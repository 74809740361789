import React, { useState, useEffect, useRef } from 'react';
import {
    Button, View, Text, TextInput, ScrollView, FlatList, BackHandler, ActivityIndicator,
    SafeAreaView, StyleSheet, Image, Dimensions, TouchableHighlight, TouchableOpacity,
} from 'react-native';
import { useFocusEffect, CommonActions } from '@react-navigation/native';

import {
    CounterState, styles, baseStyle, Separator, dimensions, Tags,
    setQueue, addtoqueuespecial,
    localized_tracktitle, localized_seriesname, localized_albumname, localized_artistname,
    smartSync
} from './App.js'

import { playSong } from "./Music";

import { Picker } from '@react-native-picker/picker';

// import FlashMessage from "react-native-flash-message";
// import { showMessage, hideMessage } from "react-native-flash-message";

import { TemplateModal, InputModal, SelectModal } from './Modal.js';

import {
    Menu,
    MenuOptions,
    MenuOption,
    MenuTrigger,
} from 'react-native-popup-menu';

function shuffleArray(array) { // in place
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
}


function playplaylistMAL(malids, playlistid, playlistname) {

    const { syncchanges } = CounterState.state;

    CounterState.setState({ 'currentplaylist': playlistid })
    CounterState.setState({ 'currentplaylistname': playlistname })
    CounterState.setState({
        'syncchanges': syncchanges.concat([
            ['currentplaylist', 'set', playlistid, null],
            ['currentplaylistname', 'set', playlistname, null],
        ])
    })
    // filter by enable disable first
    //const processed_malids = malids.filter( malids => malids[2]).map( malids => malids[0])

    const processed_malids = malids.flatMap((malid) => malid[2] ? malid[0] : [])

    console.log('processed_malids', processed_malids)

    fetch('https://animusic.moe/expandmal', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ 'malids': processed_malids, })
    }).then((response) => response.json())
        .then((json) => {
            const { syncchanges } = CounterState.state;

            CounterState.setState({ 'playlistQueue': json.slice(1) })
            CounterState.setState({
                'syncchanges': syncchanges.concat([
                    ['playlistQueue', 'set', json.slice(1), null],
                ])
            })
            playSong(json[0], 0, true)

            return null;
        })
        .catch((error) => {
            console.error(error);
            alert(error)
        });

}



async function playplaylist(playlistid, shuffled, skipfetch, playlistname) {

    const { syncchanges } = CounterState.state;

    CounterState.setState({ 'currentplaylist': playlistid })
    CounterState.setState({ 'currentplaylistname': playlistname })


    function playplayplaylist(shuffled, playlistid, playlistname) { // i actually don't know whether neccessary to pass in as arguments
        const { playlist } = CounterState.state;
        const { myplaylist } = CounterState.state;

        let modifiedplaylist = playlist.slice() // deep copy

        if (shuffled) {
            shuffleArray(modifiedplaylist)
        }

        // replace Queue
        CounterState.setState({ 'playlistQueue': modifiedplaylist.slice(1) })

        CounterState.setState({
            'syncchanges': syncchanges.concat([
                ['currentplaylist', 'set', playlistid, null],
                ['currentplaylistname', 'set', playlistname, null],
                ['playlistQueue', 'set', modifiedplaylist.slice(1), null],
            ])
        })
        playSong(modifiedplaylist[0], 0, true)

    }


    // fetch the playlist data
    function fetchPlaylistData(ids) {

        fetch('https://animusic.moe/expandid', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ 'ids': ids, })
        }).then((response) => response.json())
            .then((json) => {

                CounterState.setState({ 'playlist': json })

                // setLoading(false)
                return null; //json.movies;
            })
            .catch((error) => {
                console.error(error);
                alert(error)``
            });
    }


    let playlist
    let ignore
    if (!skipfetch) {

        // find the correct playlist by id
        let i;
        for (i = 0; i < myplaylist.length; i++) {
            if (myplaylist[i].id == playlistid) {
                break
            }
        }
        playlist = myplaylist[i]

        if (playlist.entries.length == 0) {
            alert('Playlist is empty')
            return null
        }
        // expand id 
        ignore = await fetchPlaylistData(playlist.entries)
    }

    playplayplaylist(shuffled, playlistid, playlistname) // in a separate function because we need to guarentee global var playlist is set

}

function Playplaylistbutton(props) {

    // console.log('Playplaylistbutton',props )

    return (
        <View // play button
            style={{
                justifyContent: props.justifyContent || 'center',
                marginRight: 10,
                marginLeft: 'auto' // needed for playlist view for buttons to be align right side
            }}>
            <TouchableHighlight onPress={() => {
                props.listtype == 'mal' ? playplaylistMAL(props.entries, props.playlistid, props.playlistname)
                    : playplaylist(props.playlistid, false, false, props.playlistname)
            }}
                style={{
                    backgroundColor: '#0b6b53',
                    height: 45,
                    width: 45,
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 30,
                }}>
                <Text style={{ fontSize: 20, marginLeft: 3, color: 'white' }}> ▶ </Text>
            </TouchableHighlight>
        </View>
    )
}

function Playshuffledplaylistbutton(props) {
    const skipfetch = props.skipfetch || false
    console.log('Playshuffledplaylistbutton', props.listtype)

    return (
        <View // play random button
            style={{
                justifyContent: props.justifyContent || 'center',
                marginRight: 10,
                marginLeft: 'auto'
            }}>
            <TouchableHighlight onPress={() => {
                props.listtype == 'mal' ? playplaylistMAL(props.entries, props.playlistid, props.playlistname) 
                    : 'anilist' ? playplaylistMAL(props.entries, props.playlistid, props.playlistname)
                    : playplaylist(props.playlistid, true, skipfetch, props.playlistname)
            }}
                style={{
                    backgroundColor: '#0b6b53',
                    height: 45,
                    width: 45,
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 30,
                }}>
                <Image source={require('./img/twisted_rightwards_arrows.png')}
                    style={{ width: 35, height: 35, marginTop: 4, marginLeft: 2 }} />
            </TouchableHighlight>
        </View>

    )
}


export function MyPlaylist({ route, navigation }) {

    const [sharedstate, setSharedstate] = CounterState.useState('myplaylist');
    const [sharedstate2, setSharedstate2] = CounterState.useState('currentplaylist');

    // const { editedroute } = route.params?.editedroute ?? false; // WTF see https://reactnavigation.org/docs/upgrading-from-4.x/#no-more-getparam

    const [edited, setedited] = useState(false)

    // console.log('sharedstate.myplaylist', sharedstate.myplaylist)

    React.useLayoutEffect(() => {

        navigation.setOptions({
            headerRight: () => (
                <View
                    style={{ marginRight: 10 }}>
                    <Button
                        onPress={() => navigation.openDrawer()}
                        color="grey"
                        title="☰"
                    />
                </View>
            ),
        });
    }, [navigation]);


    //// THIS WILL IN EFFECT "RELOAD" THE PAGE TWICE
    //// FOR THE SAKE OF UPDATING THE NAME OF PLAYLIST WHEN EDITED IN PLAYLIST SCREEN
    // useFocusEffect(
    //   () => {console.log('hihi',sharedstate.myplaylist)}
    //   ,[editedroute]
    // );
    useFocusEffect(
        React.useCallback(() => {
            // console.log('hihi',sharedstate.myplaylist)
            setedited(true)
        }, []) // this will run always anyway, wtf
    );

    if (edited) { setedited(false) } // resets the focuseffect

    function MyPlaylistComponent({ item }) {

        const { currentplaylist } = CounterState.state;

        const sidebutton = (item.listtype == 'norm' ?
            <View style={{ flexDirection: "row", }}>
                <Playplaylistbutton listtype={item.listtype} playlistid={item.id} playlistname={item.name} />
                <Playshuffledplaylistbutton listtype={item.listtype} playlistid={item.id} playlistname={item.name} />
            </View>
            :
            <Playshuffledplaylistbutton listtype={item.listtype} entries={item.entries} playlistid={item.id} playlistname={item.name} />
        )

        console.log('wut', item.id, currentplaylist)

        return (

            <View  // entire row
                style={(item.id == currentplaylist) ?
                    {
                        flexDirection: "row",
                        backgroundColor: '#464646',
                        marginLeft: 15
                    }
                    : {
                        flexDirection: "row",
                        marginLeft: 15
                    }}>
                <TouchableHighlight onPress={() => {
                    (item.listtype == 'norm' ?
                        // null
                        navigation.navigate('Playlist', { playlistid: item.id })
                        :
                        // null
                        navigation.navigate('PlaylistMAL', { playlistid: item.id }))
                }
                } underlayColor="#5e5e5e"
                    style={{
                        flex: 4,
                        paddingTop: 15,
                        paddingBottom: 15,
                    }}>

                    <View // for playlist name 
                        style={{
                            flex: 15,
                            flexDirection: "row",
                        }}>
                        <Text style={{ fontSize: 22, color: '#EBEBEB' }}>{item.name}</Text>

                    </View>

                </TouchableHighlight>

                {sidebutton}

            </View>

        )
    }

    function makeid(length) { // https://stackoverflow.com/a/1349426
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    function createplaylist(listtype) {
        // console.log('listtype', listtype)
        const { syncchanges } = CounterState.state;

        let id = makeid(5)
        let newplaylist = {} // need to define here or somehow last line won't be able to access variable
        if (listtype == 'norm') {
            newplaylist = { 'id': id, 'name': 'New Playlist ' + id, 'listtype': listtype, 'entries': [] }

        } else if (listtype == 'mal') {
            newplaylist = {
                'id': id, 'name': 'New MAL Playlist ' + id, 'listtype': listtype, 'user': 'Blank', 'minscore': 8,
                'allowunscored': false, 'lastsync': 0, 'entries': []
            }
        }

        CounterState.setState({ 'myplaylist': [...sharedstate.myplaylist, newplaylist] })
        CounterState.setState({
            'syncchanges': syncchanges.concat([
                ['myplaylist', 'append', [newplaylist], null],
            ])
        })
        smartSync()
    }

    // console.log('sharedstate.myplaylist',sharedstate.myplaylist)

    return (
        <View style={{ flex: 1 }}>
            <FlatList
                ItemSeparatorComponent={() => (
                    <View
                        style={{
                            height: 1,
                            width: "100%",
                            backgroundColor: "#5e5e5e",
                        }}
                    />
                )}
                data={sharedstate.myplaylist}
                renderItem={MyPlaylistComponent}
                keyExtractor={(item) => item.id}
                extraData={sharedstate2.currentplaylist, sharedstate.myplaylist}
                ListEmptyComponent={<Text style={styles.middletext}>No playlist, add a playlist below.</Text>}
            />

            <Separator height={2} />

            <View style={{
                flexDirection: "row",
                justifyContent: 'center',
            }}>

                <TouchableHighlight onPress={() => createplaylist('norm')}
                    style={{
                        backgroundColor: '#095441',
                        padding: 5,
                        marginRight: 15,
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: 10,
                    }}>
                    <Text style={{ fontSize: 18, color: '#EBEBEB' }}> Add Playlist </Text>
                </TouchableHighlight>

                <TouchableHighlight onPress={() => createplaylist('mal')}
                    style={{
                        backgroundColor: '#095441',
                        padding: 5,
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: 10,
                    }}>
                    <Text style={{ fontSize: 18, color: '#EBEBEB' }}> Add MAL Playlist </Text>
                </TouchableHighlight>


            </View>

        </View>
    )
}


export function PlaylistMAL({ route, navigation }) {
    const { playlistid } = route.params;
    const [loading, setLoading] = useState(false);
    const [playlistDetails, setPlaylistDetails] = useState([]);
    const [showoptions, setshowoptions] = useState(false);
    const [syncing, setSyncing] = useState(false);

    const [ignorethis, forcerefreshpage] = useState(Date.now());


    const [modalrenameplaylist, setmodalrenameplaylist] = useState(false);
    const [modalsetmaluser, setmodalsetmaluser] = useState(false);

    const { currentTrack } = CounterState.state;
    const { myplaylist } = CounterState.state;

    // const [sharedstate, setSharedstate] = CounterState.useState('myplaylist');

    React.useLayoutEffect(() => {
        navigation.setOptions({
            headerRight: () => (
                <View
                    style={{ marginRight: 10 }}>
                    <Button
                        onPress={() => navigation.openDrawer()}
                        color="grey"
                        title="☰"
                    />
                </View>
            ),
        });
    }, [navigation]);

    // const myplaylist = sharedstate.myplaylist

    // find the correct playlist by id
    let playlistposition;
    for (playlistposition = 0; playlistposition < myplaylist.length; playlistposition++) {
        if (myplaylist[playlistposition].id == playlistid) {
            break
        }
    }
    const playlist = myplaylist[playlistposition]

    console.log('playlistplaylistplaylist', playlist)


    const malseriesid = [] // new Map()
    // let malplaylistinfo = new Map()
    const malusername = playlist.user
    const minscore = playlist.minscore
    const includenoscore = playlist.allowunscored

    // fetch the playlist data
    const getPage = (num) => {
        return new Promise((resolve, reject) => {

            console.log('fetch page' + num)
            fetch('https://api.jikan.moe/v3/user/' + malusername + '/animelist/completed?order_by=score&sort=desc&page=' + num)
                .then((response) => response.json())
                .then((json) => {
                    console.log(json['anime'])
                    return resolve(json['anime'])
                })
                .catch(function (error) {
                    return reject(error.message)
                })
        })
    }

    const inner_fetch_user_mal_series = async () => {
        let data = []

        for (let num of [1, 2, 3, 4, 5]) {
            await getPage(num).then((animeList) => {
                if (animeList.length) {
                    data = data.concat(animeList)
                }
            })
            if (data.length % 300 != 0) { // early termination
                break
            }
        }

        return data
    }

    function filter_mal_series(data) { // applies the filter conditions (min score and include no score)
        // console.log('filtering', data.length)
        // console.log(minscore, includenoscore, typeof(includenoscore))
        data = data.filter(series => series.score >= minscore || (series.score == 0 && includenoscore))
            .map(({ mal_id, title }) => malseriesid.push([mal_id, title.replace(' (TV)','')]))
        // console.log('filtered', data.length)
    }

    function syncmal() {

        console.log('startsyncmal')
        console.log(playlist.user)


        inner_fetch_user_mal_series().then((data) => {

            const { syncchanges } = CounterState.state;

            filter_mal_series(data)

            console.log('syncmaldone')
            myplaylist[playlistposition].lastsync = Date.now()
            console.log('set state here')

            // generate a hashmap of malid in existing entries
            const existing_set = new Set()
            if (!playlist.entries) { playlist.entries = [] }
            playlist.entries.forEach((ele) => existing_set.add(ele[0]))

            console.log('existing_set', existing_set)
            console.log('malseriesid 1', malseriesid)

            // then iterate through fetch, for each not in hashmap, add to entries
            malseriesid.forEach((ele) => {
                if (!existing_set.has(ele[0])) {
                    playlist.entries.push([ele[0], ele[1], true])
                }
            })

            myplaylist[playlistposition] = playlist // probs need this, can test if don't need
            console.log('playlist', playlist)

            CounterState.setState({ 'myplaylist': myplaylist })
            CounterState.setState({
                'syncchanges': syncchanges.concat([
                    ['myplaylist.' + playlistposition, 'set', playlist, null], // entries is the bulk of the info, so update entire object
                ])
            })
            smartSync()

            setSyncing(false)
            forcerefreshpage(Date.now()) //- refresh page if needed
        })

    }

    function syncanilist() {

        const formData = {
            query: `
        query ($userName: String) { 
          MediaListCollection (userName: $userName, type: ANIME, status_not: PLANNING) { 
            lists {
              entries {
                media {
                  idMal
                  title {
                    userPreferred
                  }
                }
                score (format: POINT_10_DECIMAL)
              }
            }
          }
        }
        `,
            'variables': { 'userName': malusername }
        }

        console.log('wutAL',formData)

        fetch('https://graphql.anilist.co', {
            method: 'POST', // post will save one round trip from OPTIONS
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify(formData)
        }).then((response) => response.json())
            .then((json) => {
                const { syncchanges } = CounterState.state;
                console.log(json['data']['MediaListCollection']['lists'])


                // for each list
                //{'media': {'idMal': 6547, 'title': {'userPreferred': 'Angel Beats!'}},
                // 'score': 9}

                console.log('syncALdone')
                myplaylist[playlistposition].lastsync = Date.now()

                // generate a hashmap of malid in existing entries
                const existing_set = new Set()
                if (!playlist.entries) { playlist.entries = [] }
                playlist.entries.forEach((ele) => existing_set.add(ele[0]))

                // then iterate through fetch, for each not in hashmap, add to entries
                // FLATTEN it and filter
                json['data']['MediaListCollection']['lists'].forEach((ele2) => {

                    ele2['entries'].forEach((ele) => {

                        if (!existing_set.has(ele['media']['idMal'])) {
                            if (ele['score'] >= minscore) {
                                playlist.entries.push([ele['media']['idMal'], ele['media']['title']['userPreferred'], true])
                            }
                        }

                    })

                })

                myplaylist[playlistposition] = playlist // probs need this, can test if don't need
                console.log('playlist', playlist)

                CounterState.setState({ 'myplaylist': myplaylist })
                // CounterState.setState({
                //     'syncchanges': syncchanges.concat([
                //         ['myplaylist.' + playlistposition, 'set', playlist, null], // entries is the bulk of the info, so update entire object
                //     ])
                // })
                // smartSync()

                // setSyncing(false)
                forcerefreshpage(Date.now()) //- refresh page if needed

                return true
            })
            .catch((error) => {
                console.error(error);
                alert(error)
            })


    }

    function renamePlaylist() {
        const { syncchanges } = CounterState.state;
        myplaylist[playlistposition].name = playlistname.current.value
        CounterState.setState({ 'myplaylist': myplaylist })
        CounterState.setState({
            'syncchanges': syncchanges.concat([
                ['myplaylist.' + playlistposition + '.name', 'set', playlistname.current.value, null],
            ])
        })
        smartSync()
    }

    function deletePlaylist() {
        const { syncchanges } = CounterState.state;
        myplaylist.splice(playlistposition, 1);
        CounterState.setState({ 'myplaylist': myplaylist })
        CounterState.setState({
            'syncchanges': syncchanges.concat([
                ['myplaylist.' + playlistposition, 'del', null, null],
            ])
        })
        smartSync()
        navigation.goBack()
    }

    function setMALuser() {
        const { syncchanges } = CounterState.state;
        myplaylist[playlistposition].user = playlistuser.current.value
        CounterState.setState({ 'myplaylist': myplaylist })
        CounterState.setState({
            'syncchanges': syncchanges.concat([
                ['myplaylist.' + playlistposition + '.user', 'set', playlistuser.current.value, null],
            ])
        })
        smartSync()
    }

    function Options() {
        return (
            <View style={{}}> {/* !bareapp */}
                <Separator height={2} />
                <View style={{ flex: 1, flexDirection: "row", justifyContent: 'center', marginTop: 8 }}>
                    <View
                        style={{
                            justifyContent: 'center',
                            marginRight: 10,
                            flexDirection: 'row'
                        }}>
                        <Text style={{ marginRight: 5, color: '#EBEBEB' }}>Anime list source: </Text>
                        <Picker
                            selectedValue={playlist.listtype}
                            style={{ fontSize: 20, height: 30, width: 90, backgroundColor: 'white' }}
                            onValueChange={(itemValue, itemIndex) => {
                                const { syncchanges } = CounterState.state;
                                myplaylist[playlistposition].listtype = itemValue
                                CounterState.setState({ 'myplaylist': myplaylist })
                                CounterState.setState({
                                    'syncchanges': syncchanges.concat([
                                        ['myplaylist.' + playlistposition + '.listtype', 'set', itemValue, null],
                                    ])
                                })
                                smartSync()
                                forcerefreshpage(itemValue + 'listtype')
                            }
                            }>
                            <Picker.Item label="MAL" value={'mal'} />
                            <Picker.Item label="Anilist" value={'anilist'} />
                        </Picker>
                    </View>
                </View>
                <View style={{ flex: 1, flexDirection: "row", justifyContent: 'center', }}>
                    <View
                        style={{
                            justifyContent: 'center',
                            marginRight: 10,
                            flexDirection: 'row'
                        }}>
                        <Text style={{ marginRight: 5, color: '#EBEBEB' }}>(M)AL username: </Text>
                        <Button
                            title={playlist.user}
                            onPress={(() => { setmodalsetmaluser(true) })
                            }
                        />
                    </View>
                </View>
                <View style={{ flex: 1, flexDirection: "row", justifyContent: 'center', marginTop: 8 }}>
                    <View
                        style={{
                            justifyContent: 'center',
                            marginRight: 10,
                            flexDirection: 'row'
                        }}>
                        <Text style={{ marginRight: 5, color: '#EBEBEB' }}>Minimum Score: </Text>
                        <Picker
                            selectedValue={minscore}
                            style={{ fontSize: 20, height: 30, width: 80, backgroundColor: 'white' }}
                            onValueChange={(itemValue, itemIndex) => {
                                const { syncchanges } = CounterState.state;
                                myplaylist[playlistposition].minscore = itemValue
                                CounterState.setState({ 'myplaylist': myplaylist })
                                CounterState.setState({
                                    'syncchanges': syncchanges.concat([
                                        ['myplaylist.' + playlistposition + '.minscore', 'set', itemValue, null],
                                    ])
                                })
                                smartSync()
                                forcerefreshpage(itemValue + 'minscore')
                            }
                            }>
                            <Picker.Item label="10" value={10} />
                            <Picker.Item label="9" value={9} />
                            <Picker.Item label="8" value={8} />
                            <Picker.Item label="7" value={7} />
                            <Picker.Item label="6" value={6} />
                            <Picker.Item label="5" value={5} />
                            <Picker.Item label="4" value={4} />
                            <Picker.Item label="3" value={3} />
                            <Picker.Item label="2" value={2} />
                            <Picker.Item label="1" value={1} />
                        </Picker>
                    </View>
                </View>
                <View style={{ flex: 1, flexDirection: "row", justifyContent: 'center', marginTop: 8 }}>
                    <View
                        style={{
                            justifyContent: 'center',
                            marginRight: 10,
                            flexDirection: 'row'
                        }}>
                        <Text style={{ marginRight: 5, color: '#EBEBEB' }}>Allow unscored: </Text>
                        <Picker
                            selectedValue={playlist.allowunscored}
                            style={{ fontSize: 20, height: 30, width: 90, backgroundColor: 'white' }}
                            onValueChange={(itemValue, itemIndex) => {
                                const { syncchanges } = CounterState.state;
                                // my fucking god picker converts the value to 'true' and 'false' string
                                // so we need to convert back
                                if (itemValue == 'true') { itemValue = true } else { itemValue = false }
                                myplaylist[playlistposition].allowunscored = itemValue
                                CounterState.setState({ 'myplaylist': myplaylist })
                                CounterState.setState({
                                    'syncchanges': syncchanges.concat([
                                        ['myplaylist.' + playlistposition + '.allowunscored', 'set', itemValue, null],
                                    ])
                                })
                                smartSync()
                                forcerefreshpage(itemValue + 'Allowunscored')
                            }
                            }>
                            <Picker.Item label="Yes" value={true} />
                            <Picker.Item label="No" value={false} />
                        </Picker>
                    </View>
                </View>

                <View style={{ flex: 1, flexDirection: "row", justifyContent: 'center', marginTop: 8 }}>
                    <View
                        style={{
                            justifyContent: 'center',
                            marginRight: 10,
                            flexDirection: 'row'
                        }}>
                        <TouchableHighlight onPress={() => { setmodalrenameplaylist(true) }}
                            style={{
                                backgroundColor: '#0b6b53',
                                height: 45,
                                width: 45,
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: 30,
                            }}>
                            <Text style={{ fontSize: 20, marginLeft: 2, color: 'white' }}> ✎ </Text>
                        </TouchableHighlight>
                        <View style={{ justifyContent: 'center' }}><Text style={{ marginLeft: 5, color: '#EBEBEB' }}>Edit Playlist Name</Text></View>
                    </View>
                    <View
                        style={{
                            justifyContent: 'center',
                            marginRight: 10,
                            flexDirection: 'row'
                        }}>

                        <TouchableHighlight onPress={() => { deletePlaylist() }}
                            style={{
                                backgroundColor: '#0b6b53',
                                height: 45,
                                width: 45,
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: 30,
                            }}>
                            <Text style={{ fontSize: 20, color: 'white' }}> ❌ </Text>
                        </TouchableHighlight>
                        <View style={{ justifyContent: 'center' }}><Text style={{ marginLeft: 5, color: '#EBEBEB' }}>Delete Playlist</Text></View>
                    </View>
                </View>

                {(loading ?
                    <View style={{ flex: 1, flexDirection: "row", justifyContent: 'center', marginTop: 16 }}>
                        <Text> Last Sync: {playlist.lastsync}</Text>
                    </View> :
                    playlist.user ?
                        <View style={{ flex: 1, flexDirection: "row", justifyContent: 'center', marginTop: 16 }}>
                            <Text> Last Sync: {playlist.lastsync}</Text>
                            <View style={{ width: 20 }}></View>
                            {(syncing ? <ActivityIndicator size="small" color="#fff9a9" /> :
                                <Button
                                    title='sync'
                                    onPress={(() => {
                                        setSyncing(true);
                                        if (myplaylist[playlistposition].listtype == 'mal') {
                                            syncmal();
                                        } else if (myplaylist[playlistposition].listtype == 'anilist') {
                                            syncanilist();
                                        } else {
                                            alert('listtype not applicable: ' + myplaylist[playlistposition].listtype);
                                        }
                                    })
                                    }
                                />
                            )}
                        </View>
                        : null)}
                <Separator height={2} />
            </View>
        )
    }


    // Convert playlist map to array for flatlist
    // https://stackoverflow.com/a/46156019
    // const playlistmal_array = Object.keys(playlistmal).map(key => dataname[key])
    // https://stackoverflow.com/a/56795800
    // const playlistmal_array = Array.from(playlistmal, ([name, value]) => ({ name, value }));

    function malseriesmodify(malid, action) { // set entry = true or false

        const { syncchanges } = CounterState.state;

        let j;
        for (j = 0; j < playlist['entries'].length; j++) {
            if (playlist['entries'][j][0] == malid) {
                playlist['entries'][j][2] = action
                // ???? somehow playlist manages to save to counterstate, without explicitly doing so
                CounterState.setState({
                    'syncchanges': syncchanges.concat([
                        ['myplaylist.' + playlistposition + '.entries.' + j + '.2', 'set', action, null],
                    ])
                })
                break
            }
        }
        smartSync()
        forcerefreshpage(malid + 'malseriesmodify' + action)
    }

    function PlaylistMALComponent({ item }) {
        const { currentTrack } = CounterState.state;
        const { mainrootkey } = CounterState.state;

        // TODO figure out how to get this working with screen resize
        // const width = responsiveScreenWidth(100);

        // console.log(item)

        // shouldComponentUpdate() {
        //   return false
        // }

        // let highlight = false;

        // if (item[0] == currentTrack.seriesid) {
        //   highlight = true;
        // }


        content = (
            <View style={{ marginLeft: 15, }}>
                <TouchableHighlight
                    onPress={() => {
                        console.log(item[1]);
                        navigation.dispatch({
                            ...CommonActions.setParams({ search: item[1] }),
                            source: mainrootkey,
                        });
                        navigation.navigate('Home', { 'search': item[1] })
                    }}
                    underlayColor="#5e5e5e"
                    style={{
                        flex: 1,
                        paddingTop: 8,
                        paddingBottom: 8,
                    }}>
                    <View
                        style={{
                            //backgroundColor: 'powderblue',
                            flexDirection: "row",
                        }}>
                        <Text style={item[2] ?
                            {
                                fontSize: 19,
                                color: '#EBEBEB',
                            }
                            : {
                                fontSize: 19,
                                color: '#6c6c6c',
                            }}
                        >{item[1]}</Text>
                    </View>
                </TouchableHighlight>
            </View>
        )


        return (

            <View  // entire row
                style={{
                    flexDirection: "row",
                    backgroundColor: '#2d2d2d',
                }}>

                {content}

                <View  // for options
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        height: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                    <Menu>
                        <MenuTrigger>
                            <Text style={styles.ellipsis}>{`⋮`}</Text>
                        </MenuTrigger>
                        <MenuOptions>
                            {(item[2] ?
                                <MenuOption onSelect={() => { malseriesmodify(item[0], false) }}>
                                    <Text style={{ color: 'black' }}>Disable</Text>
                                </MenuOption> :
                                <MenuOption onSelect={() => { malseriesmodify(item[0], true) }}>
                                    <Text style={{ color: 'black' }}>Enable</Text>
                                </MenuOption>
                            )}
                        </MenuOptions>
                    </Menu>
                </View>



            </View>
        )
    }

    const playlistname = useRef(playlist.name)
    const playlistuser = useRef(playlist.user)

    // Cannot use ternary below instead of this - will trigger "A text node cannot be a child of a <View>" errors
    let content
    if (loading) {
        content = (<ActivityIndicator size="large" color="#a6a6ff" />)
    } else {
        content = (

            <FlatList
                ItemSeparatorComponent={() => (
                    <View
                        style={{
                            height: 1,
                            width: "100%",
                            backgroundColor: "#5e5e5e",
                        }}
                    />
                )}
                data={playlist['entries']}
                renderItem={PlaylistMALComponent}
                keyExtractor={(item) => item[0].toString()}
                initialNumToRender={20} // doesn't seem to work
                extraData={currentTrack} // TODO consider highlighting series <- need seriesid in currenttrack
                ListEmptyComponent={<Text style={styles.middletext}>No items in playlist. Configure options above.</Text>}
            />
        )
    }

    return (
        <View style={{ flex: 1 }}>
            <View style={{ flexDirection: "row", marginTop: 15, marginBottom: 15 }}>
                <Text style={{ fontSize: 22, marginLeft: 10, color: '#EBEBEB', marginTop: 8, marginRight: 20 }}>
                    {playlist.name}
                </Text>
                <View
                    style={{
                        marginRight: 10
                    }}>
                    <TouchableHighlight onPress={() => { (showoptions ? setshowoptions(false) : setshowoptions(true)) }}
                        style={{
                            backgroundColor: '#0b6b53',
                            height: 45,
                            width: 45,
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: 30,
                        }}>
                        <Text style={{ fontSize: 20, marginLeft: 3, color: 'white' }}> ☰ </Text>
                    </TouchableHighlight>
                </View>

                <View style={{ marginLeft: 'auto', flex: 1, flexDirection: 'row' }}>
                    {/* <Playplaylistbutton listtype={playlist.listtype} playlist={playlist.id} justifyContent={'flex-start'} /> */}
                    <Playshuffledplaylistbutton listtype={playlist.listtype} entries={playlist.entries} playlistname={playlist.name} justifyContent={'flex-start'} skipfetch={false} />

                </View>
            </View>

            {showoptions ? <Options /> : null}


            {content}

            <InputModal visibility={modalrenameplaylist} setVisibility={setmodalrenameplaylist}
                cancelFunc={console.log} confirmFunc={renamePlaylist}
                inputs={[{
                    title: 'Rename Playlist',
                    defaultValue: playlist.name,
                    reference: playlistname
                }]} />
            <InputModal visibility={modalsetmaluser} setVisibility={setmodalsetmaluser}
                cancelFunc={console.log} confirmFunc={setMALuser}
                inputs={[{
                    title: 'Set MAL username',
                    defaultValue: playlist.user,
                    reference: playlistuser
                }]} />
        </View>
    )
}



export function Playlist({ route, navigation }) {
    const { playlistid, edited } = route.params;
    const [loading, setLoading] = useState(true);
    const [playlistDetails, setPlaylistDetails] = useState([]);
    const [showoptions, setshowoptions] = useState(false);
    const [modalrenameplaylist, setmodalrenameplaylist] = useState(false);

    const { currentTrack } = CounterState.state;
    const { myplaylist } = CounterState.state;

    const [ignorethis, forcerefreshpage] = useState(Date.now());

    const { playlistmodified } = CounterState.state;
    const [sharedstate, setSharedstate] = CounterState.useState('playlistmodified');

    // if (edited) {
    //   forcerefreshpage(Date.now())
    //   navigation.dispatch(CommonActions.setParams({ edited: '' }));
    // }

    // console.log('heyyy2', playlistmodified, sharedstate.playlistmodified);
    useFocusEffect(
        () => {
            // console.log('heyyy3', playlistmodified, sharedstate.playlistmodified);
            if (sharedstate.playlistmodified) {
                // forcerefreshpage(Date.now());

                if (sharedstate.playlistmodified == playlistid) {
                    setLoading(true)
                    fetchPlaylistData(playlist.entries);
                    console.log('FETCHING')
                }
                CounterState.setState({ 'playlistmodified': '' })
            }
        }
    );

    // CounterState.setState({ 'playlistrootkey': route.key })

    React.useLayoutEffect(() => {
        navigation.setOptions({
            headerRight: () => (
                <View
                    style={{ marginRight: 10 }}>
                    <Button
                        onPress={() => navigation.openDrawer()}
                        color="grey"
                        title="☰"
                    />
                </View>
            ),
        });
    }, [navigation]);

    // hmm depending on norm or mal will be different

    // find the correct playlist by id
    let playlistposition;
    for (playlistposition = 0; playlistposition < myplaylist.length; playlistposition++) {
        if (myplaylist[playlistposition].id == playlistid) {
            break
        }
    }
    const playlist = myplaylist[playlistposition]

    // fetch the playlist data
    function fetchPlaylistData(ids) {

        fetch('https://animusic.moe/expandid', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ 'ids': ids, })
        }).then((response) => response.json())
            .then((json) => {

                // populate state for search
                setPlaylistDetails(json)

                CounterState.setState({ 'playlist': json })

                setLoading(false)
                return null; //json.movies;
            })
            .catch((error) => {
                console.error(error);
                alert(error)
            });
    }

    // fetch album details 
    useEffect(() => {
        fetchPlaylistData(playlist.entries);
        console.log('FETCHING')
    }, []);


    function renamePlaylist() {
        const { syncchanges } = CounterState.state;
        // console.log('currentplaylist', myplaylist)
        myplaylist[playlistposition].name = playlistname.current.value
        // console.log('updatedplaylist', myplaylist)

        CounterState.setState({ 'myplaylist': myplaylist })
        CounterState.setState({
            'syncchanges': syncchanges.concat([
                ['myplaylist.' + playlistposition + '.name', 'set', playlistname.current.value, null],
            ])
        })
        smartSync()
        // const setParamsAction = CommonActions.setParams({
        //   params: { edited: true },
        //   key: 'MyPlaylist',
        // });
        // this.props.navigation.dispatch(setParamsAction);

        // navigation.dispatch({
        //   ...CommonActions.setParams({ editedroute: true }),
        //   source: { name: 'MyPlaylist' },
        // });
    }

    function deletePlaylist() {
        const { syncchanges } = CounterState.state;

        // console.log('currentplaylist', myplaylist)
        myplaylist.splice(playlistposition, 1);
        // console.log('updatedplaylist', myplaylist)

        CounterState.setState({ 'myplaylist': myplaylist })
        CounterState.setState({
            'syncchanges': syncchanges.concat([
                ['myplaylist.' + playlistposition, 'del', null, null],
            ])
        })
        smartSync()

        navigation.goBack() // previous page won't refresh on its own, but we force refresh twice in MyPlaylist
        // navigation.navigate('MyPlaylist', { edited:true}) // must have params or previous page won't refresh
    }

    function removeFromPlaylist(resultid) {
        // we cannot assume that trackid is unique
        // so the only unique thing is resultid
        // we don't want to re-fetch, so cannot directly use resultid as index
        // instead, we scan through `playlistDetails` 
        // console.log(resultid, playlistDetails)

        const { syncchanges } = CounterState.state;

        let i
        for (i = 0; i < playlistDetails.length; i++) {
            if (playlistDetails[i].resultid == resultid) { break }
        }
        playlistDetails.splice(i, 1)
        setPlaylistDetails(playlistDetails)

        myplaylist[playlistposition].entries.splice(i, 1)
        CounterState.setState({ 'myplaylist': myplaylist })
        CounterState.setState({
            'syncchanges': syncchanges.concat([
                ['myplaylist.' + playlistposition + '.entries.' + i, 'del', null, null],
            ])
        })
        smartSync()


        forcerefreshpage(Date.now()) // somehow setplaylistdetails don't refresh it
    }

    function Options() {
        return (
            <View style={{}}> {/* !bareapp */}
                <Separator height={2} />
                <View style={{ flex: 1, flexDirection: "row", justifyContent: 'center', }}>
                    <View
                        style={{
                            justifyContent: 'center',
                            marginRight: 10,
                            flexDirection: 'row'
                        }}>

                        <TouchableHighlight onPress={() => { setmodalrenameplaylist(true) }}
                            style={{
                                backgroundColor: '#0b6b53',
                                height: 45,
                                width: 45,
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: 30,
                            }}>
                            <Text style={{ fontSize: 20, marginLeft: 2, color: 'white' }}> ✎ </Text>
                        </TouchableHighlight>
                        <View style={{ justifyContent: 'center' }}><Text style={{ marginLeft: 5, color: '#EBEBEB' }}>Edit Playlist Name</Text></View>
                    </View>
                    <View
                        style={{
                            justifyContent: 'center',
                            marginRight: 10,
                            flexDirection: 'row'
                        }}>

                        <TouchableHighlight onPress={() => { deletePlaylist() }}
                            style={{
                                backgroundColor: '#0b6b53',
                                height: 45,
                                width: 45,
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: 30,
                            }}>
                            <Text style={{ fontSize: 20, color: 'white' }}> ❌ </Text>
                        </TouchableHighlight>
                        <View style={{ justifyContent: 'center' }}><Text style={{ marginLeft: 5, color: '#EBEBEB' }}>Delete Playlist</Text></View>
                    </View>
                </View>
                <Separator height={2} />
            </View>
        )
    }


    function PlaylistComponent({ item }) {
        const { currentTrack } = CounterState.state;

        // console.log(item)
        // TODO figure out how to get this working with screen resize
        // const width = responsiveScreenWidth(100);
        const width = dimensions.width

        // console.log(width)

        let highlight = false;


        if (item == currentTrack.trackid) {
            highlight = true;
        }


        let content
        if (width >= 600) { // wide screen, single line
            // TODO: make this responsive to screen size changes

            const split = 0.65
            const firsthalf = width * split
            const secondhalf = width * (1 - split)
            // console.log('firsthalf', firsthalf)

            content = (<View>
                <TouchableHighlight
                    onPress={() => {
                        const { syncchanges } = CounterState.state;

                        setQueue(item, 'playlist');
                        CounterState.setState({ 'currentplaylist': playlist.id });
                        CounterState.setState({ 'currentplaylistname': playlist.name });
                        CounterState.setState({
                            'syncchanges': syncchanges.concat([
                                ['currentplaylist', 'set', playlistid, null],
                                ['currentplaylistname', 'set', playlistname, null],
                            ])
                        })
                        playSong(item, 0, true)
                    }}
                    underlayColor="#5e5e5e"
                    style={{
                        flex: 1,
                        paddingTop: 8,
                        paddingBottom: 8,
                    }}>
                    <View // for track name 
                        style={{
                            //backgroundColor: 'powderblue',
                            flexDirection: "row",
                            //https://stackoverflow.com/questions/32030050/how-can-you-float-right-in-react-native
                        }}>
                        <View style={{ width: firsthalf }}>
                            <View style={{ flexDirection: 'row', marginLeft: 15 }}>
                                <Text numberOfLines={1} style={{ fontSize: 19, color: '#EBEBEB' }}>{localized_tracktitle(item)}</Text>
                                <View style={{ marginLeft: 'auto', flexDirection: 'row' }}>
                                    <Text>{item.disc_track}</Text>
                                    <Tags tags={item.tag} />
                                    <Tags tags={item.tags} />
                                </View>
                            </View>
                        </View>
                        <View style={{ marginLeft: 10, paddingRight: 30, width: secondhalf }}>
                            <Text numberOfLines={1} style={{ fontSize: 16, color: '#EBEBEB' }}>{localized_seriesname(item)}</Text>
                        </View>
                    </View>
                </TouchableHighlight>
            </View>)
        } else { // smol screen, two line
            content = (<View style={{ flex: 1, marginRight: 35 }}>
                <TouchableHighlight
                    onPress={() => {
                        setQueue(item, 'playlist'); playSong(item)
                    }}
                    underlayColor="#5e5e5e"
                    style={{
                        paddingTop: 8,
                        paddingBottom: 8,
                    }}>
                    <View>
                        <View // first row 
                            style={{
                                //backgroundColor: 'powderblue',
                                marginBottom: 3,
                                flex: 1,
                                marginLeft: 15,
                                flexDirection: "row",
                            }}>
                            <Text style={{ fontSize: 19, color: '#EBEBEB' }}>{localized_tracktitle(item)}</Text>
                            <Text style={{ textAlign: 'right', flex: 1, color: '#EBEBEB', textAlignVertical: 'center' }}>{item.disc_track}</Text>
                        </View>
                        <View // second row 
                            style={{
                                marginLeft: 20,
                                //backgroundColor: 'powderblue',
                                flexDirection: "row",
                            }}>
                            <Tags tags={item.tag} />
                            <Tags tags={item.tags} />
                            <Text style={{ fontSize: 19, color: '#EBEBEB', marginLeft: 5, textAlignVertical: 'center' }}>{localized_seriesname(item)}</Text>
                        </View>
                    </View>

                </TouchableHighlight>
            </View>)
        }


        return (

            <View  // entire row
                style={highlight ?
                    {
                        flexDirection: "row",
                        // height: albumTrackHeight,
                        // width: dimensions.width,
                        backgroundColor: '#464646',
                    }
                    : {
                        flexDirection: "row",
                        // height: albumTrackHeight,
                        // width: dimensions.width,
                        //backgroundColor: 'lightgrey',
                    }}>

                {content}

                <View  // for options
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        height: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                    <Menu>
                        <MenuTrigger>
                            <Text style={styles.ellipsis}>{`⋮`}</Text>
                        </MenuTrigger>
                        <MenuOptions>
                            <MenuOption onSelect={() => { navigation.navigate('Album', { screen: 'Album', params: { albumitem: item } }) }}>
                                {/* <MenuOption onSelect={() => { navigation.navigate('Home', { screen: 'Album', params: { albumitem: item.albumid } }) }}> */}
                                <Text style={{ color: 'black' }}>Go to album</Text>
                            </MenuOption>
                            <MenuOption onSelect={() => { removeFromPlaylist(item.resultid) }}>
                                <Text style={{ color: 'black' }}>Remove from playlist</Text>
                            </MenuOption>
                        </MenuOptions>
                    </Menu>
                </View>



            </View>
        )
    }

    const playlistname = useRef(playlist.name)

    // Cannot use ternary below instead of this - will trigger "A text node cannot be a child of a <View>" errors
    let content
    if (loading) {
        content = (<ActivityIndicator size="large" color="#a6a6ff" />)
    } else {
        content = (<FlatList
            ItemSeparatorComponent={() => (
                <View
                    style={{
                        height: 1,
                        width: "100%",
                        backgroundColor: "#5e5e5e",
                    }}
                />
            )}
            data={playlistDetails}
            renderItem={PlaylistComponent}
            keyExtractor={(item) => item.resultid}
            extraData={currentTrack}
            ListEmptyComponent={<Text style={styles.middletext}>No items in playlist. Add songs by clicking the 3 dots next to tracks or albums.</Text>}
        />)
    }

    return (
        <View style={{ ...styles.pageStyle, justifyContent: 'flex-start' }}>
            <View style={{ flexDirection: "row", marginTop: 15, marginBottom: 15 }}>
                <Text style={{ fontSize: 22, marginLeft: 10, color: '#EBEBEB', marginTop: 8, marginRight: 20 }}>
                    {playlist.name}
                </Text>
                <View
                    style={{
                        marginRight: 10
                    }}>
                    <TouchableHighlight onPress={() => { (showoptions ? setshowoptions(false) : setshowoptions(true)) }}
                        style={{
                            backgroundColor: '#0b6b53',
                            height: 45,
                            width: 45,
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: 30,
                        }}>
                        <Text style={{ fontSize: 20, marginLeft: 3, color: 'white' }}> ☰ </Text>
                    </TouchableHighlight>
                </View>

                <View style={{ marginLeft: 'auto', flex: 1, flexDirection: 'row' }}>
                    {/* <Playplaylistbutton listtype={playlist.listtype} playlist={playlist.id} justifyContent={'flex-start'} /> */}
                    <Playshuffledplaylistbutton listtype={playlist.listtype} playlistid={playlist.id} playlistname={playlist.name} justifyContent={'flex-start'} skipfetch={true} />

                </View>
            </View>

            {showoptions ? <Options /> : null}

            {content}

            <InputModal visibility={modalrenameplaylist} setVisibility={setmodalrenameplaylist}
                cancelFunc={console.log} confirmFunc={renamePlaylist}
                inputs={[{
                    title: 'Rename Playlist',
                    defaultValue: playlist.name,
                    reference: playlistname
                }]} />
        </View>
    )
}
