// import {Modal, Pressable} from 'react-native' // works on app, doesn't work on web, supports back button, no backdrop dismiss
// import Modal from 'react-dumb-modal' // doesn't work on app, works on web
// import Modal from 'react-native-modalbox'; // doesn't work at all
// import Modal from 'react-native-modal'; // works on app, fails miserably on web, supports back button, supports backdrop dismiss
import Modal from 'modal-enhanced-react-native-web'; // doesn't work on app, works on web, supports backdrop dismiss

import { Picker } from '@react-native-picker/picker';
import React, { useState, useEffect } from 'react';
import {
  Button, View, Text, TextInput, StyleSheet, TouchableOpacity
} from 'react-native';


export function SelectModal(props) {
  // This adds an additional select element to TemplateModal
  // Additional props:
  // - initialvalue = initialvalue 
  // - label = select label
  // - value = select value
  // Ignored props:
  // - additionalElements
  // - output = output is set to the value for the TextInput here

  const [label, setLabel] = useState('')
  const [value, setValue] = useState('')

  const pickeritems = []

  useEffect(() => {
    // console.log('selectmodal defaultValue', props.default)
    const defaultValue = props.defaultValue || [props.label[0], props.value[0]]
    // setup
    setLabel(defaultValue[0]);
    setValue(defaultValue[1])
  }, []);


  for (let i = 0; i < props.label.length; i++) {
    pickeritems.push(
      <Picker.Item label={props.label[i]} value={props.value[i]} />
    )
  }



  //props.options.forEach((option) => pickeritems.push(
  //	<Picker.Item label={option} value={option} />
  //))

  const additionalElements = (
    <Picker
      selectedValue={label}
      style={{ fontSize: 20, height: 30, backgroundColor: 'white' }} // !bareapp
      onValueChange={(itemValue, itemIndex) => { setLabel(itemValue); setValue(props.value[itemIndex]) }
      }>
      {pickeritems}
    </Picker>
  )


  return (
    <TemplateModal visibleModal={props.visibleModal} setvisibleModal={props.setvisibleModal} cancelFunc={props.cancelFunc} confirmFunc={props.confirmFunc} additionalElements={additionalElements}
      output={value} headerStyle={props.headerStyle} headerText={props.headerText}
    />
  )


}

// Modal with cancel/confirm buttons
export function ConfirmModal(props) {
  // (Required)
  // - visiblility = state value for whether modal is visible
  // - setVisiblility = func to change modal visibility
  // - title = Text for header
  // - confirmFunc = Function to run on confirm press

  // (Optional)
  // - output = argument passed into confirmFunc
  // - type = Which color scheme the modal should follow
  // - desc = Text for description
  // - cancelFunc = Func called on cancel press
  // - additionalElements = additional elements between header text and buttons

  // type also accepts an object 
  // { btnText, btnBack, borderColor }

  let typeStyle

  const type = props.type || 'default'
  const title = props.title
  const desc = props.description || null

  const cancelFunc = props.cancelFunc || (() => null)
  const confirmFunc = props.confirmFunc
  const output = props.output || null

  const additionalElements = props.additionalElements || null


  // resolve accent colors
  switch (type) {
    case 'warning':
      typeStyle = {
        borderColor: '#FF3D3D',
        btnBack: '#E2C6C6',
        btnText: '#C70000',
      }
      break

    case 'default':
      typeStyle = {
        borderColor: '#FFA134 ',
        btnBack: '#D7CEB6',
        btnText: '#A06000',
      }
      break

    default: {
      typeStyle = {
        borderColor: type.borderColor,
        btnBack: type.btnBack,
        btnText: type.btnText,
      }
    }
  }

  const modalStyles = StyleSheet.create({
    modalCard: {
      alignSelf: 'center',
      backgroundColor: '#DEDEDE',
      maxWidth: 500,
      width: '90%',
      borderTopWidth: 6,
      borderTopColor: typeStyle.borderColor,
      borderRadius: 4,
      alignItems: 'center',
      paddingTop: 10,
      paddingLeft: 10,
      paddingRight: 10,
      paddingBottom: 15,
    },

    title: {
      fontWeight: '500',
      fontSize: 23,
      marginBottom: 10,
      color: '#0B0B0B',
      textAlign: 'center',
    },

    desc: {
      color: '#393939',
      textAlign: 'center',
      marginBottom: 20,
      maxWidth: '80%'
    },

    btnCont: {
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      width: '100%',
    },

    btn: {
      fontWeight: '600',
      borderRadius: 3,
      width: 120,
      height: 40,
      textAlign: 'center',
      lineHeight: 40,
    },

    cancelBtn: {
      backgroundColor: '#D3D3D3',
      color: '#5A5A5A',
    },

    confirmBtn: {
      backgroundColor: typeStyle.btnBack,
      color: typeStyle.btnText
    }
  })


  return (
    <Modal
      isVisible={props.visibility}
      onBackdropPress={() => { props.setVisibility(false); cancelFunc() }}
    >

      {/* modal card */}
      <View style={modalStyles.modalCard}>

        {/* title */}
        <Text style={modalStyles.title}>{title}</Text>

        {/* description */}
        <Text style={modalStyles.desc}>{desc}</Text>

        {/* extras */}
        {additionalElements}

        {/* button cont */}
        <View style={modalStyles.btnCont}>

          {/* cancel */}
          <TouchableOpacity onPress={() => { props.setVisibility(false); cancelFunc() }}>
            <Text style={[modalStyles.cancelBtn, modalStyles.btn]}>CANCEL</Text>
          </TouchableOpacity>

          {/* confirm */}
          <TouchableOpacity onPress={() => { props.setVisibility(false); confirmFunc(output) }}>
            <Text style={[modalStyles.confirmBtn, modalStyles.btn]}>CONFIRM</Text>
          </TouchableOpacity>

        </View>

      </View>

    </Modal>
  )

}


// Modal with binary buttons
export function TwoChoicesModal(props) {
  // (Required)
  // - visiblility = state value for whether modal is visible
  // - setVisiblility = func to change modal visibility
  // - title = Text for header
  // - confirmFunc = Function to run on confirm press
  // - option1 = Text for option 1 (left)
  // - option2 = Text for option 2 (right)

  // (Optional)
  // - type = Which color scheme the modal should follow
  // - desc = Text for description
  // - additionalElements = additional elements between header text and buttons

  // type also accepts an object 
  // { btnText, btnBack, borderColor }

  let typeStyle

  const type = props.type || 'default'
  const title = props.title
  const desc = props.description || null

  const cancelFunc = props.cancelFunc || (() => null)
  const confirmFunc = props.confirmFunc

  const additionalElements = props.additionalElements || null

  const option1 = props.option1 || 'option 1'
  const option2 = props.option2 || 'option 2'

  // resolve accent colors
  switch (type) {
    case 'warning':
      typeStyle = {
        borderColor: '#FF3D3D',
        btnBack: '#D3D3D3',
        btnText: '#5A5A5A',
      }
      break

    case 'default':
      typeStyle = {
        borderColor: '#FFA134 ',
        btnBack: '#D7CEB6',
        btnText: '#A06000',
      }
      break

    default: {
      typeStyle = {
        borderColor: type.borderColor,
        btnBack: type.btnBack,
        btnText: type.btnText,
      }
    }
  }

  const modalStyles = StyleSheet.create({
    modalCard: {
      alignSelf: 'center',
      backgroundColor: '#DEDEDE',
      maxWidth: 500,
      width: '90%',
      borderTopWidth: 6,
      borderTopColor: typeStyle.borderColor,
      borderRadius: 4,
      alignItems: 'center',
      paddingTop: 10,
      paddingLeft: 10,
      paddingRight: 10,
      paddingBottom: 15,
    },

    title: {
      fontWeight: '500',
      fontSize: 23,
      marginBottom: 10,
      color: '#0B0B0B',
      textAlign: 'center',
    },

    desc: {
      color: '#393939',
      textAlign: 'center',
      marginBottom: 20,
      maxWidth: '80%'
    },

    btnCont: {
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      width: '100%',
    },

    btn: {
      fontWeight: '600',
      borderRadius: 3,
      width: 120,
      height: 40,
      textAlign: 'center',
      lineHeight: 40,
    },

    cancelBtn: {
      backgroundColor: '#D3D3D3',
      color: '#5A5A5A',
    },

    confirmBtn: {
      backgroundColor: typeStyle.btnBack,
      color: typeStyle.btnText
    }
  })


  return (
    <Modal
      isVisible={props.visibility}
      onBackdropPress={() => { props.setVisibility(false); cancelFunc() }}
    >

      {/* modal card */}
      <View style={modalStyles.modalCard}>

        {/* title */}
        <Text style={modalStyles.title}>{title}</Text>

        {/* description */}
        <Text style={modalStyles.desc}>{desc}</Text>

        {/* extras */}
        {additionalElements}

        {/* button cont */}
        <View style={modalStyles.btnCont}>

          {/* cancel */}
          <TouchableOpacity onPress={() => { props.setVisibility(false); confirmFunc(option1) }}>
            <Text style={[modalStyles.cancelBtn, modalStyles.btn]}>{option1}</Text>
          </TouchableOpacity>

          {/* confirm */}
          <TouchableOpacity onPress={() => { props.setVisibility(false); confirmFunc(option2) }}>
            <Text style={[modalStyles.confirmBtn, modalStyles.btn]}>{option2}</Text>
          </TouchableOpacity>

        </View>

      </View>

    </Modal>
  )

}

// Modal with input boxes
export function InputModal(props) {
  // (Required)
  // - visiblility = state value for whether modal is visible
  // - setVisiblility = func to change modal visibility
  // - confirmFunc = Function to run on confirm press
  // - inputs = List of objects containing input box details
  // - reference = useRef hooks for handling input value
  // - cancelFunc = Func called on cancel press // empty input here

  // (Optional)
  // - output = argument passed into confirmFunc
  // - desc = Text for description

  // inputs: (all required)
  // - title = title for input
  // - placeholder = placeholder in input box
  // - required = bool if this input box should contain a value or not
  // - type = type of input. takes in https://reactnative.dev/docs/textinput#textcontenttype


  const modalStyles = StyleSheet.create({
    modalCard: {
      alignSelf: 'center',
      backgroundColor: '#DEDEDE',
      maxWidth: 500,
      width: '90%',
      borderRadius: 4,
      alignItems: 'center',
      paddingTop: 15,
      paddingLeft: 5,
      paddingRight: 5,
      paddingBottom: 15,
    },

    desc: {
      color: '#393939',
      textAlign: 'center',
      marginBottom: 20,
      fontSize: 16,
      maxWidth: '85%'
    },

    btnCont: {
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      width: '100%',
    },

    btn: {
      fontWeight: '600',
      borderRadius: 3,
      width: 120,
      height: 40,
      textAlign: 'center',
      lineHeight: 40,
    },

    cancelBtn: {
      backgroundColor: '#D3D3D3',
      color: '#5A5A5A',
    },

    confirmBtn: {
      backgroundColor: '#C0E0BD',
      color: '#2E4325',
    },

    inputParentCont: {
      marginBottom: 10,
      width: '100%',
      alignItems: 'center',
    },

    inputCont: {
      width: '85%',
      marginBottom: 15,
    },

    inputTitle: {
      color: 'black',
      fontSize: 15,
      marginBottom: 5,
    },

    inputBox: {
      backgroundColor: '#C7C7C7',
      color: 'white',
      borderWidth: 0,
      borderRadius: 5,
      height: 40,
      paddingLeft: 10,
      color: 'black',
    }
  })

  let desc

  const output = props.output || null
  const cancelFunc = props.cancelFunc || (() => null)
  const confirmFunc = props.confirmFunc


  if (props.description) {
    desc = <Text style={modalStyles.desc}>{props.description}</Text>
  } else {
    desc = null
  }
  const inputs = props.inputs

  const inputElements = inputs.map(input =>
    <View style={modalStyles.inputCont}>
      <Text style={modalStyles.inputTitle}>{input.title}</Text>
      <TextInput
        style={modalStyles.inputBox}
        title={input.title}
        placeholder={input.placeholder ? input.placeholder : ''}
        textContentType={input.type ? input.type : 'none'}
        ref={input.reference ? input.reference : null}
        defaultValue={input.defaultValue ? input.defaultValue : ''}
        onChangeText={(text) => { input.reference.current.value = text }}
      />
    </View>
  )

  return (
    <Modal
      isVisible={props.visibility}
      onBackdropPress={() => { props.setVisibility(false); cancelFunc() }}
    >

      {/* modal card */}
      <View style={modalStyles.modalCard}>

        {/* description */}
        {desc}

        {/* input elements */}
        <View style={modalStyles.inputParentCont}>
          {inputElements}
        </View>

        {/* button cont */}
        <View style={modalStyles.btnCont}>

          {/* cancel */}
          <TouchableOpacity onPress={() => { props.setVisibility(false); cancelFunc() }}>
            <Text style={[modalStyles.cancelBtn, modalStyles.btn]}>CANCEL</Text>
          </TouchableOpacity>

          {/* confirm */}
          <TouchableOpacity onPress={() => { props.setVisibility(false); confirmFunc(output) }}>
            <Text style={[modalStyles.confirmBtn, modalStyles.btn]}>CONTINUE</Text>
          </TouchableOpacity>

        </View>

      </View>

    </Modal>
  )
}
export function TemplateModal(props) {
  // Template Modal with 1 header text, optional additional elements, cancel - confirm buttons
  // Props:
  // (Required)
  // - visibleModal = state value for whether modal is visible
  // - setvisibleModal = func to change modal visibility
  // (Optional)
  // - headerText = Text for header text
  // - headerStyle = Style for header text
  // - cancelFunc = Func called on cancel press
  // - confirmFunc = Func called on confirm press, must accept 1 argument
  // - output = argument passed into confirmFunc
  // - additionalElements = additional elements between header text and buttons

  const cancelFunc = props.cancelFunc || (() => null) // technically null cannot be function-called, null(), but seems like no errors anyway
  const confirmFunc = props.confirmFunc || ((a) => null)
  const headerStyle = props.headerStyle || { color: '#EBEBEB', fontSize: 18, marginBottom: 5, fontWeight: 'bold' }
  const headerText = props.headerText || ''
  const additionalElements = props.additionalElements || null
  const output = props.output || null

  return (
    <Modal
      isVisible={props.visibleModal}
      onBackdropPress={() => { props.setvisibleModal(false); cancelFunc() }}
    >
      <View style={{
        width: '80%', alignSelf: 'center',
        backgroundColor: 'grey'
      }}>
        <View style={{ marginTop: 15, marginLeft: '5%' }}>
          <Text style={headerStyle}>{headerText}</Text>
          {additionalElements}
        </View>
        <View style={{
          flexDirection: 'row', justifyContent: 'center', marginTop: 15,
          marginBottom: 15
        }}>
          <Button title="Cancel" onPress={() => { props.setvisibleModal(false); cancelFunc() }} />
          <View style={{ width: '15%' }} />
          <Button title="Confirm" onPress={() => { props.setvisibleModal(false); confirmFunc(output) }} />
        </View>
      </View>
    </Modal>
  )
}
