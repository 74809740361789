
import React, { useState, useEffect } from 'react';
import {
    Button, View, Text, TextInput, ScrollView, FlatList, BackHandler, ActivityIndicator,
    SafeAreaView, StyleSheet, Image, Dimensions, TouchableHighlight, TouchableOpacity,
} from 'react-native';
import { useFocusEffect, CommonActions } from '@react-navigation/native';

import {
    CounterState, styles, baseStyle, Separator, dimensions, Tag, Tags, AddToPlaylistModal,
    setQueue, addtoqueuespecial,
    localized_tracktitle, localized_seriesname, localized_albumname, localized_artistname,
    smartSync
} from './App.js'

import { playSong } from "./Music";


import FlashMessage from "react-native-flash-message";
import { showMessage, hideMessage } from "react-native-flash-message";

import { TemplateModal, InputModal, SelectModal } from './Modal.js';

import {
    Menu,
    MenuOptions,
    MenuOption,
    MenuTrigger,
} from 'react-native-popup-menu';

import { Svg, Line } from 'react-native-svg';

// autocomplete. if doesn't work try https://github.com/mrlaessig/react-native-autocomplete-input
// untested with app
// import Autocomplete from '@animusicmoe/react-native-autocomplete-input';
import Autocomplete from './autocomplete';


const searchStyles = StyleSheet.create({
    boxContainer: {
        marginTop: 30,
        width: '90%',
        alignSelf: 'center',
        marginBottom: 90,
        borderRadius: 5,
        zIndex: 1, // !bare_app // needed for web cos layers mash together // not needed for android cos it makes the input box unclickable
        maxWidth: 800,
    },

    // for android
    boxContainerAbs: {
        width: '100%',
        position: 'absolute',
        zIndex: 1,
    },

    // around input box
    boxBar: {
        borderRadius: 4,
        backgroundColor: '#2E2C2A',
        fontSize: 15,
    },

    // input box
    boxInput: {
        marginTop: 8,
        marginBottom: 8,
        outlineWidth: 0, // !bare_app // gets rid of the fucking outline on web // gives error at runtime on android
        backgroundColor: '#2E2C2A',
        fontSize: 16,
        paddingLeft: 10,
        paddingRight: 30,
        color: '#fff',
        fontWeight: 'bold',

        borderRadius: 0,
        borderColor: 'transparent',
    },

    // dropdown background
    boxList: {
        borderRadius: 0,
        borderColor: 'transparent',
        backgroundColor: '#414141',
    },

    // drop down items
    boxListItem: {
        fontSize: 16,
        paddingTop: 4,
        paddingBottom: 4,
        paddingLeft: 10,
        color: 'white',
    },

    // container of search clear X  
    clearContainer: {
        top: 0,
        right: 0,
        position: 'absolute',
        height: 56,
    },

    clearTouchable: {
        height: '100%',
        width: 60,
        alignItems: 'center',
        justifyContent: 'center',
    },

    clearIcon: {
        height: 20,
        width: 20,
    }
})

function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}


function fetch_autocomplete() {

    fetch('https://static.animusic.moe/static/misc/autocomplete.json').then(res => res.json()).then((json) => {
        CounterState.setState({ 'autocomplete': json });

    });

}

export function HomeScreen({ route, navigation }) { // search is also in homescreen


    const [text, setText] = useState('');
    const [searchresults, setSearchResults] = useState([]);
    const [recentlyresults, setrecentlyresults] = useState({ 'recently_added': [], 'recently_listened': [] });
    const [searchmode, setSearchmode] = useState(false);
    const [loading, setLoading] = useState(true);
    const [query, setQuery] = useState('');
    const [editingquery, setEditingQuery] = useState(false);
    const [counterstateloaded, setCounterstateloaded] = useState(false);
    // const { currentTrack } = CounterState.state; 
    //const [showaudioplayer, setshowaudioplayer] = CounterState.useState('showaudioplayer');

    const [currentTrack, setcurrentTrack] = CounterState.useState('currentTrack'); // need to track currenttrack status to highlight current playing track
    const [recently_listened, setrecently_listened] = CounterState.useState('recently_listened');
    const [recently_added, setrecently_added] = CounterState.useState('recently_added');
    const [recently_updated, setrecently_updated] = CounterState.useState('recently_updated');


    const [showaddtoplaylistmodal, setshowaddtoplaylistmodal] = useState(false)
    const [addtoplaylistid, setaddtoplaylistid] = useState(1337)
    const [addtoplaylisttype, setaddtoplaylisttype] = useState('norm')

    // const [prefillsearch, setprefillsearch] = useState('');
    const { search } = route.params;

    const { autocomplete } = CounterState.state;
    const { currentpage_search } = CounterState.state;
    const { currentpage_album } = CounterState.state;
    // const { recently_updated } = CounterState.state; 

    React.useLayoutEffect(() => {
        navigation.setOptions({
            headerRight: () => (
                <View
                    style={{ marginRight: 10 }}>
                    <Button
                        onPress={() => navigation.openDrawer()}
                        color="grey"
                        title="☰"
                    />
                </View>
            ),
        });
    }, [navigation]);

    useFocusEffect(() => console.log('search', search, query));

    CounterState.setState({ 'mainrootkey': route.key })

    if (search && query != search) {
        setQuery(search)
        setLoading(true); setSearchmode(true); fireSearch(search);
        navigation.dispatch(CommonActions.setParams({ search: '' }));
    }

    // if (prefillsearch) {
    //   setQuery(prefillsearch)
    //   setprefillsearch('')
    //   setLoading(true); setSearchmode(true); fireSearch(query);
    // }

    // convert back button into clear searchmode first
    useFocusEffect(
        React.useCallback(() => {
            const onBackPress = () => {
                if (searchmode) {
                    setSearchmode(false);
                    setText('');
                    return true;
                } else {
                    return false;
                }
            };

            BackHandler.addEventListener('hardwareBackPress', onBackPress);

            return () =>
                BackHandler.removeEventListener('hardwareBackPress', onBackPress);
        }, [searchmode, setSearchmode])
    );

    useEffect(() => { // TODO: might want useFocusEffect because homescreen doesn't rerender to call this
        console.log('recently_updated check');
        if ((Date.now() - recently_updated) > (1000 * 60)) { // * 60
            console.log('recently_updated SET');
            CounterState.setState({ 'recently_updated': Date.now() })
        }
    });

    useEffect(() => {
        fetch_recently();
        fetch_latest_version_number();
        // console.log('fetch recently updated', recently_updated)
    }, []); // recently_updated


    useEffect(() => {
        fetch_autocomplete()
        // console.log('fetch autocomplete')
    }, []);

    useEffect(() => { // restore previous state if available
        // DOES NOT WORK BECAUSE (I THINK) TAKES TIME TO LOAD THE STATE AND THIS HAPPENS ONE REFRESH BEFORE
        // WORKAROUND BELOW
        // console.log('hey', currentpage_search, currentpage_album)

        // // if search
        // if (currentpage_search){
        //   setQuery(currentpage_search)
        // }

        // // if album
        // if (currentpage_album){
        //   navigation.navigate('Album', { albumitem: currentpage_album })
        // }

        // // console.log('fetch autocomplete')
    }, []);

    // this is the real start-up script
    // TODO: this is janky, see App.js startup script for the correct way
    if (!counterstateloaded) {
        console.log('home console run')
        if (currentpage_search != null) {

            if (currentpage_search) {
                setQuery(currentpage_search);
                fireSearch(currentpage_search);
                setSearchmode(true)
            }

            // if album
            if (currentpage_album) {
                navigation.navigate('Album', { screen: 'Album', params: { albumitem: currentpage_album } })
            }
            setCounterstateloaded(true)
        }
    }

    useFocusEffect( // since no way to detect back button from album, we detect focus instead

        () => {
            const { syncchanges } = CounterState.state;
            // console.log('test whether run this too often')
            if (counterstateloaded) {
                if (currentpage_album) {
                    // console.log('test whether set this too often')
                    CounterState.setState({ 'currentpage_album': '' });
                    CounterState.setState({
                        'syncchanges': syncchanges.concat([
                            ['currentpage_album', 'set', '', null],
                        ])
                    })
                    smartSync();
                }
            }
        }
    );



    function fetch_recently() {


        fetch('https://animusic.moe/recent')
            .then(res => res.json())
            .then((json) => {
                // console.log("SET RECENTL ADDDED", json['recently_added'])
                setrecentlyresults(json)
                // CounterState.setState({ 'recently_added': json['recently_added'] });
                // CounterState.setState({ 'recently_listened': json['recently_listened'] });
                return null;
            })
            .catch((error) => {
                console.error(error);
                alert(error)
            });

    }

    function fetch_latest_version_number() {

        fetch('https://animusic.moe/version')
            .then(res => res.json())
            .then((json) => {
                console.log("fetch_latest_version_number", json)
                CounterState.setState({ 'latest_version': json });
                // CounterState.setState({ 'recently_listened': json['recently_listened'] });
                return null;
            })
            .catch((error) => {
                console.error(error);
                alert(error)
            });

    }

    function Clearsearchbutton() {

        return (<View style={{
            top: 12,
            position: 'absolute',
            left: '91%',
            flex: 1,
            zIndex: 1,
            justifyContent: "center",
            alignItems: "center"
        }}>
            <TouchableOpacity
                style={{
                    backgroundColor: "grey",
                    paddingHorizontal: 10,
                    paddingVertical: 3,
                    borderRadius: 5,
                }}
                onPress={() => {
                    const { syncchanges } = CounterState.state;
                    setQuery(''); setSearchmode(false); setEditingQuery(false); CounterState.setState({ 'currentpage_search': '' });
                    CounterState.setState({
                        'syncchanges': syncchanges.concat([
                            ['currentpage_search', 'set', '', null],
                        ])
                    })
                }}
            >
                <Text style={{
                    fontSize: 20,
                    fontWeight: 'bold',
                    color: 'white',
                }}>X</Text>
            </TouchableOpacity></View>)

    }

    function fireSearch(text) {

        // console.log('firesearch on ', text)
        const { syncchanges } = CounterState.state;
        CounterState.setState({ 'currentpage_search': text })
        CounterState.setState({
            'syncchanges': syncchanges.concat([
                ['currentpage_search', 'set', text, null],
            ])
        })

        if (text.length == 0) {
            setSearchmode(false)
            return null
        }

        fetch('https://animusic.moe/search', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ 'query': text, })
        }).then((response) => response.json())
            .then((json) => {

                // populate state for search
                CounterState.setState({ 'search': json })
                setSearchResults(json)

                setLoading(false)
                return null;
            })
            .catch((error) => {
                console.error(error);
                alert(error)
            });

    }

    // console.log('trackid' + currentTrack.trackid)

    function SearchResultComponent({ item }) {

        const { currentTrack } = CounterState.state;

        if (item.trackid) {
            return (
                <View  // entire row
                    style={(item.trackid == currentTrack.trackid) ?
                        styles.highlightedrow
                        : styles.normalrow
                    }>
                    <View  // for image
                        style={styles.viewalbumimage}>
                        <Image source={{ uri: item.albumimage ? 'https://static.animusic.moe/static/img/album/' + item.albumimage + '.jpg' : 'https://static.animusic.moe/static/img/album/blank.png' }}
                            style={styles.albumImage} />

                    </View>

                    <TouchableHighlight onPress={() => { setQueue(item, 'search'); playSong(item); }} underlayColor="#166b55"
                        // need this duplicate flex or layout fucked
                        style={{ flex: 4, }}>

                        <View // for text
                            style={{
                                flex: 4,
                                flexDirection: "column"
                            }}>


                            <View // for top text
                                style={styles.topTextView}>
                                <Text numberOfLines={1} style={styles.topText}>{localized_tracktitle(item)}</Text>
                            </View>

                            <View // for bottom text
                                style={{
                                    flex: 1,
                                    flexDirection: "row"
                                }}>
                                <Tags tags={item.tag} />
                                <View // for album/artist
                                    style={styles.bottomAlbum}>

                                    {(item.artistname_eng ?
                                        <Text numberOfLines={2} style={styles.bottomAlbumText}>{localized_seriesname(item)} / {localized_artistname(item)}</Text>
                                        : <Text numberOfLines={2} style={styles.bottomAlbumText}>{localized_seriesname(item)}</Text>)}

                                </View>

                            </View>


                        </View>
                    </TouchableHighlight>

                    {/* <OptionsMenu /> */}

                    <View  // for options
                        style={styles.menuStyle}>
                        <Menu>
                            <MenuTrigger style={styles.dotStyle}>
                                <Text style={styles.ellipsis}>{`⋮`}</Text>
                            </MenuTrigger>
                            <MenuOptions>
                                <MenuOption onSelect={() => addtoqueuespecial(item)}>
                                    <Text style={{ color: 'black' }}>Add to queue</Text>
                                </MenuOption>
                                <MenuOption onSelect={() => navigation.navigate('Album', { screen: 'Album', params: { albumitem: item } })}>
                                    <Text style={{ color: 'black' }}>Go to album</Text>
                                </MenuOption>
                                <MenuOption onSelect={() => { setaddtoplaylistid(item.trackid); setaddtoplaylisttype('track'); setshowaddtoplaylistmodal(true) }}>
                                    <Text style={{ color: 'black' }}>Add to playlist</Text>
                                </MenuOption>
                            </MenuOptions>
                        </Menu>
                    </View>

                </View>
                //     
            )
        } else { // type == 'album' because no .trackid
            return (
                <View  // entire row
                    style={(item.albumid == currentTrack.albumid) ?
                        styles.highlightedrow
                        : styles.normalrow}>
                    <View  // for image
                        style={styles.viewalbumimage}>
                        <Image source={{ uri: item.albumimage ? 'https://static.animusic.moe/static/img/album/' + item.albumimage + '.jpg' : 'https://static.animusic.moe/static/img/album/blank.png' }}
                            style={styles.albumImage} />

                    </View>


                    <TouchableHighlight onPress={() => navigation.navigate('Album', { screen: 'Album', params: { albumitem: item } })} underlayColor="#166b55"
                        // need this duplicate flex or layout fucked
                        style={{ flex: 4, }}>

                        <View // for text
                            style={{
                                flex: 4,
                                flexDirection: "column",
                            }}>


                            <View // for top text
                                style={styles.topTextView}>
                                <Text numberOfLines={1} style={styles.topText}>{localized_albumname(item)}</Text>
                            </View>

                            <View // for bottom text
                                style={{
                                    flex: 1,
                                    flexDirection: "row",
                                }}>
                                <Tag name='Album' />
                                <Tags tags={item.tags} />
                                <View // for album/artist
                                    style={styles.bottomAlbum}>

                                    <Text numberOfLines={2} style={styles.bottomAlbumText}>{localized_seriesname(item)} </Text>
                                </View>

                            </View>


                        </View>
                    </TouchableHighlight>

                    {/* <OptionsMenu /> */}
                    <View  // for options
                        style={styles.menuStyle}>
                        <Menu>
                            <MenuTrigger style={styles.dotStyle}>
                                <Text style={styles.ellipsis}>{`⋮`}</Text>
                            </MenuTrigger>
                            <MenuOptions>
                                <MenuOption onSelect={() => addtoqueuespecial(item)}>
                                    <Text style={{ color: 'black' }}>Add album to queue</Text>
                                </MenuOption>
                                <MenuOption onSelect={() => { setaddtoplaylistid(item.albumid); setaddtoplaylisttype('album'); setshowaddtoplaylistmodal(true) }}>
                                    <Text style={{ color: 'black' }}>Add to playlist</Text>
                                </MenuOption>
                            </MenuOptions>
                        </Menu>
                    </View>

                </View>
                //     
            )
        }

    }



    let films = [];
    if (query === '') {
        films = [];
    } else if (editingquery === false) {
        films = [];
    } else {
        const regex = new RegExp(`${escapeRegExp(query.trim())}`, 'i');
        films = autocomplete
            .filter(film => film.search(regex) >= 0)
            .sort(function (a, b) { return Math.abs(b.length - query.length) - Math.abs(a.length - query.length) })
            .reverse()
            .slice(0, 8)
    }
    // console.log('films', films)

    const comp = (a, b) => a.toLowerCase().trim() === b.toLowerCase().trim();

    // console.log('added',recently_added)
    // console.log('search',searchresults)

    const searchClose = (<View style={searchStyles.clearContainer}>
        <TouchableOpacity style={searchStyles.clearTouchable} onPress={() => {
            const { syncchanges } = CounterState.state;
            setQuery('');
            setSearchmode(false);
            setEditingQuery(false);
            CounterState.setState({ 'currentpage_search': '' })
            CounterState.setState({
                'syncchanges': syncchanges.concat([
                    ['currentpage_search', 'set', '', null],
                ])
            })

        }}>
            <Svg style={searchStyles.clearIcon}>
                {/* params below can't be taken into a const or added using styles cos it's processed for android using react-native-svg */}
                <Line x1="0" y1="0" x2="100%" y2="100%" stroke='#c98282' strokeWidth="3" strokeLinecap='round' />
                <Line x1="100%" y1="0" x2="0" y2="100%" stroke='#c98282' strokeWidth="3" strokeLinecap='round' />
            </Svg>
        </TouchableOpacity>
    </View>
    )


    // search bar input and dropdown
    const searchBar = (<View style={searchStyles.boxContainerAbs}>
        <Autocomplete
            style={searchStyles.boxInput}
            autoCapitalize="none"
            placeholderTextColor="#9b9b9b"
            listContainerStyle={searchStyles.boxList}
            inputContainerStyle={{ borderWidth: 0, zIndex: 2 }}
            listStyle={searchStyles.boxList}
            containerStyle={searchStyles.boxBar}
            data={films.length === 1 && comp(query, films[0]) ? [] : films}
            value={query}
            onChangeText={text => { setQuery(text); setEditingQuery(true) }}
            placeholder="Search series or track name"
            onSubmitEditing={() => { setLoading(true); setSearchmode(true); fireSearch(query); setEditingQuery(false) }}
            renderItem={({ item, i }) => autoCompleteItem(item, i)}
        />

        { query ? searchClose : null}
    </View>
    )


    // autocomplete dropdown list item template
    const autoCompleteItem = (item, i) => (
        <TouchableOpacity onPress={() => {
            setQuery(item);
            setLoading(true);
            setSearchmode(true);
            fireSearch(item);
            setEditingQuery(false)
        }}>
            <Text style={searchStyles.boxListItem}>{item}</Text>
        </TouchableOpacity>
    )


    // CONTENT DETERMIATION

    let content

    if (searchmode && loading) { // query loading page
        content = <ActivityIndicator size="large" color="#a6a6ff" />
    }
    else if (searchmode) { // query page
        content = (
            <FlatList
                data={searchresults}
                renderItem={SearchResultComponent}
                keyExtractor={(item) => item.resultid}
                extraData={currentTrack.currentTrack}
                ListEmptyComponent={<Text style={styles.middletext}>Nothing found.</Text>}
            />
        )
    }
    else { // recently added page
        content = (
            <>
                <Text style={styles.headerText}>Recently Added Albums</Text>

                <FlatList
                    data={recentlyresults['recently_added']}
                    renderItem={SearchResultComponent}
                    keyExtractor={(item) => item.resultid}
                    extraData={currentTrack.currentTrack}
                />
            </>
        )
    }



    return (<ScrollView style={{ width: '100%', }} fillViewport="true">
        <View style={styles.pageStyle}>

            <View style={searchStyles.boxContainer} >
                {searchBar}
            </View>

            {content}

            <AddToPlaylistModal visibleModal={showaddtoplaylistmodal}
                setvisibleModal={setshowaddtoplaylistmodal}
                id={addtoplaylistid}
                type={addtoplaylisttype}
            />
        </View>
    </ScrollView>
    )

}
