
import React, { useState, useEffect, useRef } from 'react';
import {
  Button, View, Text, TextInput, ScrollView, FlatList, BackHandler, ActivityIndicator,
  SafeAreaView, StyleSheet, Image, Dimensions, TouchableHighlight, TouchableOpacity, SectionList,
  Platform, Pressable
} from 'react-native';
import Slider from '@react-native-community/slider';
import { NavigationContainer, CommonActions } from '@react-navigation/native';
import { navigationRef } from './RootNavigation';
import { useFocusEffect } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { createDrawerNavigator, DrawerContentScrollView, DrawerItemList } from '@react-navigation/drawer';
import {
  responsiveScreenHeight,
  responsiveScreenWidth,
  responsiveFontSize,
  useResponsiveWidth,
  useResponsiveScreenWidth
} from "react-native-responsive-dimensions";

import * as RootNavigation from './RootNavigation.js';
import { TemplateModal, InputModal, SelectModal, TwoChoicesModal } from './Modal.js';
import { Settings } from './SettingsScreen.js'
import { FeaturedPlaylist, fetchfeaturedplaylistsongs, playfeaturedplaylist, extendfeaturedplaylist } from './FeaturedPlaylistScreen.js'
import { MyPlaylist, PlaylistMAL, Playlist } from './MyPlaylistScreen.js'
import { HomeScreen } from './HomeScreen.js'
import { AlbumScreen } from './Album.js'


import { SharedState } from "react-native-shared-state"; // need to modify import TODO

import FlashMessage from "react-native-flash-message";
import { showMessage, hideMessage } from "react-native-flash-message";

// import RNPickerSelect from 'react-native-picker-select'; // select picker component
import { Picker } from '@react-native-picker/picker';



import { useFonts } from 'expo-font'; //  !bareapp

// identity
// untested with web - should not invoke though
import RNUserIdentity, { ICLOUD_ACCESS_ERROR } from 'react-native-user-identity' // https://www.npmjs.com/package/react-native-shared-state



// unknown
import 'react-native-gesture-handler'; // , try to treeshake this shit if it doesn't work
//import AsyncStorage from '@react-native-community/async-storage';

// audio player
// import TrackPlayer from 'react-native-track-player';
// import { useTrackPlayerEvents, TrackPlayerEvents, useTrackPlayerProgress, STATE_PLAYING, STATE_BUFFERING } from 'react-native-track-player';

import { MenuProvider } from 'react-native-popup-menu';
import {
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
} from 'react-native-popup-menu';


export const CounterState = new SharedState({

  // INTERNAL VARIABLES
  // counter: 0,
  // showaudioplayer: true, // flag for showing control panel | CURRENTLY ALWAYS SHOW
  userclickedplay: false, // for showing buffering ... in audio control panel
  userclickedpause: false, // for pausing during buffering ...
  search: [], // store search results for setqueue
  album: [], // store album results for setqueue
  playlist: [], // store playlist results for setqueue
  // addSongMode: 'search',
  playbackStatus: {}, // used by Music.js to store status
  // recently_listened: [], set into local state instead
  // recently_added: [], set into local state instead
  // autocomplete: [], no longer used
  // documenttitle: '', no longer implemented this way
  recently_updated: 0, // not fully used yet, for refreshing recently updated
  mainrootkey: '', // for navigation.dispatch -> set param for HomeScreen so as to navigate to home + trigger search
  playlistmodified: '', // when adding tracks to playlist, this is used to trigger  PlaylistScreen to refresh


  // 'normal' sync, not used anymore
  // tosync_myplaylist: false,
  // lastsync_myplaylist: 0,
  // lastsync_playlist: 0, // note, playlist is 'currentplaying'
  // lastsync_settings: 0,

  syncerror: false, // to pop up modal for syncerror
  syncing: false, // so show syncing indicator

  // blankplaceholder: false,
  loadedplaceholder: 1,
  forceMusicPanelRefresh: 0, // to refresh music panel

  // SETTINGS VARIABLES
  identity: false, // not used yet
  // loggedin: false, // check usertoken for loggedin instead
  usertoken: null, // set to string if loggedin
  passwordprotected: false, // boolean, true if loggedin with password
  volume: 0.4,

  syncchanges: [],
  lastsynctime: 1,

  // sync everything below this
  preferredLang: 'romengjap',

  currentpage_album: null, // used in HomeScreen to restore previous view

  // EXTERNAL VARIABLES
  currentTrack: { 'tracktitle_translit': null, 'duration': 0, 'trackid': null },  // store currenttrack for control panel results
  playlistQueue: [], // store (search) playback queue to feed into audioplayer
  playlistQueued: [], // store DONE playback queue to feed into audioplayer
  playlistQueueSpecial: [], // store ADDTOQUEUE playback queue to feed into audioplayer
  playlistgreylist: {}, // contains 'greylisted' tracks for infinite FeaturedPlaylists

  currentplaylist: '', // this is playlist id
  currentplaylistname: null, // this is playlist display name

  currentpage_search: null, // used in HomeScreen to restore previous view

  myplaylist: [{
    'id': 'a', 'name': 'New playlist', 'listtype': 'norm',
    'entries': [7238, 7241, 7243]
  },
  ], // this stores ALL playlist info


});



import Audiocontrolpanel, { playSong, play, pause, stop, testadd1, testadd2, clearPlaylist, skip, seek } from "./Music";


Text.defaultProps = Text.defaultProps || {}
Text.defaultProps.style = { color: 'white' }


function addToPlaylist(item) {  // TODO check => move to music.js if needed
  const [playlistQueue, setPlaylistQueue] = CounterState.useState('playlistQueue');
  CounterState.setState({ 'playlistQueue': [...playlistQueue, item] })  // json
}



function login() { // TODO actually just allow user to connect 3 different states
  const [identity, setidentity] = CounterState.useState('identity');

  if (Platform.OS === 'ios') { // display 'connect with ios'


  } else if (Platform.OS === 'android') { // display 'connect with android'
    RNUserIdentity.getUserId({
      androidAccountSelectionMessage: 'Choose an account to connect:'
    })

  } else { // probably web, ask for email. 'connect with email'


  } // if already logged in

  // after log in, should retrieve shit
}


export const dimensions = Dimensions.get('window');
const imageHeight = Math.min(300, Math.round(dimensions.height / 9));
const searchResultHeight = imageHeight + 20;
const albumTrackHeight = 40;
export const audiocontrolpanelHeight = Math.min(350, Math.round(dimensions.height / 7));

const imageAlbumPageHeight = Math.min(300, Math.round(dimensions.height / 3));

export const baseStyle = {
  color: '#EBEBEB', // F5F5F5 // E0E0E0 // D6D6D6
}

// #ebebeb #d3d3d3 #bcbcbc #a4a4a4 #8d8d8d #757575 #5e5e5e #464646 #2f2f2f #171717 #000000

// background/base probs #2f2f2f

export const styles = StyleSheet.create({
  lightText: {
    color: '#EBEBEB', // F5F5F5 // E0E0E0 // D6D6D6
  },
  highlightedrow: { // style the highlighted track entry
    flexDirection: "row",
    backgroundColor: 'green',
    marginBottom: 10,
    // width: '100%'
  },
  normalrow: { // style the track entry
    flexDirection: "row",
    backgroundColor: '#063d2f',
    marginBottom: 10,
    // width: '100%' // 1000 because flex 100% => hwo long the item is
  },
  pageStyle: {
    flex: 1,
    width: '100%',
    maxWidth: 1000,
    //  alignItems: 'center', // this will cause children to be fixed width FULL
    alignSelf: 'center', // 
  },
  viewalbumimage: {
    flex: 0,
    flexBasis: imageHeight,
  },
  albumImage: {
    width: imageHeight,
    height: imageHeight,
    maxHeight: 400,
    minHeight: 50,
  },
  topTextView: {
    flex: 1,
    //backgroundColor: '#063d2f',
    //flexDirection: "row",
    paddingTop: 5,
    justifyContent: 'center',
    paddingRight: 25
  },
  topText: {
    fontSize: 20,
    color: '#ebebeb',
    marginLeft: 10
  },
  bottomTags: {
    //backgroundColor: 'lightgreen',
    marginLeft: 10,
  },
  bottomTagsText: {
    fontSize: 18,
    color: 'lightgreen',
    fontWeight: 'bold'
  },
  tagbaseview: {
    alignSelf: 'flex-start',
    justifyContent: "center",
    borderRadius: 16,
    paddingLeft: 7,
    paddingRight: 7,
    marginLeft: 10,
    paddingTop: 1,
    paddingBottom: 1
    // height: 32,
    //margin: 4,
  },
  tagbasetext: {
    fontSize: 14,
    fontWeight: 'bold'
  },
  bottomAlbum: {
    //backgroundColor: 'powderblue',
    flex: 1,
    paddingLeft: 10,
    paddingRight: 25
    // paddingRight: 16, // 12 is definitely too little
  },
  bottomAlbumText: {
    fontSize: 16, color: '#ebebeb',
    // paddingRight: 70,
  },
  ellipsis: { fontSize: 25, color: '#ebebeb', paddingLeft: 10, paddingRight: 10 },
  nowPlayingAlbumImage: {
    width: audiocontrolpanelHeight,
    height: audiocontrolpanelHeight,
  },
  controlButtons: {
    width: 48,
    height: 48,
    marginLeft: 5,
    marginRight: 5,
    // paddingBottom:10,
  },
  headerText: {
    color: '#EBEBEB',
    fontSize: 25,
    fontWeight: 'bold',
    marginBottom: 20,
    textAlign: 'center'
  },
  middletext: { fontSize: 18, color: '#EBEBEB', textAlign: 'center' },
  menuStyle: {
    position: 'absolute',
    //top: 23,//'28%',
    right: 0,
  },
  dotStyle: {
    width: 35,
    height: imageHeight,
    borderRadius: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  settingsButton: {
    marginBottom: 15,
    backgroundColor: "#0b6b53",
    paddingHorizontal: 15,
    paddingVertical: 5,
    borderRadius: 5,
  },
  settingsButtonText: {
    fontSize: 20,
    fontWeight: 'bold',
    color: 'white',
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center"
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center"
  },
  modalContainer: {
    backgroundColor: 'red'
  },
  modalBigContainer: {
    backgroundColor: 'yellow'
  },
  scrollModal: {
    backgroundColor: 'blue'
  }
});


export function songComplete() {
  const { currentTrack } = CounterState.state;
  const { playlistQueue } = CounterState.state;
  const { playlistQueued } = CounterState.state;
  const { playlistQueueSpecial } = CounterState.state;
  const { syncchanges } = CounterState.state;

  const { currentplaylist } = CounterState.state;
  const { currentplaylistname } = CounterState.state;

  // console.log('calling songcomplete')
  // called when current track finishes playing


  console.log('playlistQueueSpecial', playlistQueueSpecial)
  console.log('playlistQueued', playlistQueued)
  console.log('playlistQueue', playlistQueue)

  let collectsyncchanges = []

  let toplaysong = null
  // check queue special
  if (playlistQueueSpecial.length > 0) {
    // only store last 10 played songs
    CounterState.setState({ 'playlistQueued': playlistQueued.concat(currentTrack).slice(-10) })
    collectsyncchanges.push(['playlistQueued', 'appendcap', currentTrack, 10])
    // CounterState.setState({ 'currentTrack': playlistQueueSpecial[0] }) // not needed, playsong will set it
    CounterState.setState({ 'playlistQueueSpecial': playlistQueueSpecial.slice(1) })
    collectsyncchanges.push(['playlistQueueSpecial', 'slice', 1, null])

    toplaysong = playlistQueueSpecial[0]


  } else if (playlistQueue.length > 0) { // else check queue

    // console.log('take from queue')
    // find position in queue really. or have to set queue properly when addSong
    CounterState.setState({ 'playlistQueued': playlistQueued.concat(currentTrack).slice(-10) })
    // CounterState.setState({ 'currentTrack': playlistQueue[0] }) // not needed, playsong will set it

    CounterState.setState({ 'playlistQueue': playlistQueue.slice(1) })
    collectsyncchanges.push(['playlistQueue', 'slice', 1, null])

    toplaysong = playlistQueue[0]
  } else {
    // do not move to playlistQueued if non-playlist
    CounterState.setState({ 'playlistQueued': playlistQueued.concat(currentTrack).slice(-10) })
    collectsyncchanges.push(['playlistQueueSpecial', 'slice', 1, null])
    if (currentplaylist) {
      CounterState.setState({ 'currentTrack': { 'tracktitle_translit': 'End of playlist: ' + currentplaylistname, 'duration': 0 } })
    }
    collectsyncchanges.push(['currentTrack', 'set', { 'tracktitle_translit': 'End of playlist: ' + currentplaylistname, 'duration': 0 }, null])
  }

  // we need to saved syncchanges before it is retrieved in playSong, otherwise changes lost
  // see https://snack.expo.io/kfzXtvfqk
  CounterState.setState({ 'syncchanges': syncchanges.concat(collectsyncchanges) })

  if (toplaysong) {
    playSong(toplaysong, 0, false)
  }

  // if is playing featured playlist, then we need to extend the playlist
  if (currentplaylist && !isNaN(currentplaylist)) { // featured playlist are numeric
    console.log('watching for featured extend')
    if (playlistQueue.length == 2) { // prevent race condition and calling twice
      console.log('featured extend')
      fetchfeaturedplaylistsongs(currentplaylist, currentplaylistname, extendfeaturedplaylist)
      // possible to extend queue here, but we may want to reuse extend code
      // so made into function - extendfeaturedplaylist
      //.then(
      //	(tracks) => {
      //		CounterState.setState({ 'playlistQueue': playlistQueue.concat(tracks) })
      //		}
      //	)
    }
  } else {
    smartSync()
  }

}

export function playLastTrack() {
  const { currentTrack } = CounterState.state;
  const { playlistQueue } = CounterState.state;
  const { playlistQueued } = CounterState.state;
  const { playlistQueueSpecial } = CounterState.state;
  const { syncchanges } = CounterState.state;

  if (playlistQueued.length == 0) { // in case some retard press back 100 times
    playSong(currentTrack)
    return false
  }

  // we do not know whether current song comes from queue or queuespecial, but we expect it next, so put in special
  CounterState.setState({ 'playlistQueueSpecial': playlistQueueSpecial.concat(currentTrack) })
  CounterState.setState({ 'currentTrack': playlistQueued[playlistQueued.length - 1] })
  // remove from queued so dont duplicate it
  CounterState.setState({ 'playlistQueued': playlistQueued.slice(1, playlistQueued.length - 1) })

  CounterState.setState({
    'syncchanges': syncchanges.concat([
      ['playlistQueueSpecial', 'append', [currentTrack], null],
      ['playlistQueued', 'slicereverse', 1, null],
    ])
  })

  playSong(playlistQueued[playlistQueued.length - 1], 0, true)
}


export function addtoqueuespecial(item) {
  // const [playlistQueueSpecial, setplaylistQueueSpecial] = CounterState.useState('playlistQueueSpecial');
  // console.log('add to queue special')
  // console.log(item)

  const { playlistQueueSpecial } = CounterState.state;
  const { syncchanges } = CounterState.state;

  if (item.trackid) { // if item is track, will have trackid
    CounterState.setState({ 'playlistQueueSpecial': playlistQueueSpecial.concat(item) })

    CounterState.setState({
      'syncchanges': syncchanges.concat([
        ['playlistQueueSpecial', 'append', [item], null],
      ])
    })
    smartSync()

  } else { // else has to be album (for now)
    // query album details, then add to playlistQueueSpecial

    fetch('https://animusic.moe/album?' + new URLSearchParams({
      albumid: item.albumid,
    }), {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
    }).then((response) => response.json())
      .then((json) => {
        const { syncchanges } = CounterState.state;

        // console.log('add to queue album')
        // console.log(json.track_details)

        // flatten list first
        let all_tracks = []
        for (let i = 0; i < json.track_details.length; i++) {
          all_tracks = all_tracks.concat(json.track_details[i]['data'])
        }

        CounterState.setState({ 'playlistQueueSpecial': playlistQueueSpecial.concat(all_tracks) })
        CounterState.setState({
          'syncchanges': syncchanges.concat([
            ['playlistQueueSpecial', 'append', all_tracks, null],
          ])
        })
        smartSync()

        return null; //json.movies;
        // }).then(()=> {const { playlistQueueSpecial } = CounterState.state; console.log('queuespecial',playlistQueueSpecial)
      })
      .catch((error) => {
        console.error(error);
        alert(error)
      });

  }
  // todo: if currently no song, just addSong

  showMessage({
    message: "Added to queue successfully!",
    type: "info",
  });
}

export function setQueue(item, addSongMode) {
  // this function figures out where the item is in the page (search or album)
  // then sets the songs below the item as the queue (playlistQueue)

  // since this function is only called in mainscreen, we also reset playlist

  const { search } = CounterState.state;
  const { album } = CounterState.state;
  const { playlist } = CounterState.state;
  const { syncchanges } = CounterState.state;

  let collectsyncchanges = []

  // reset current playlist
  CounterState.setState({ 'currentplaylist': '' })
  CounterState.setState({ 'currentplaylistname': null })
  collectsyncchanges.push(['currentplaylist', 'set', '', null])
  collectsyncchanges.push(['currentplaylistname', 'set', '', null])

  if (addSongMode != 'nothing') { // nothing else other than search and album at the moment, so this block always run

    //.then(()=>{console.log('stoppedsong')}); // not actually needed, only TrackPlayer.stop();

    // remove all current queue?
    if (addSongMode == 'search') {
      // console.log('addsongmode search')

      // go through the search to see where item is
      // by right can just use item.resultid... figure out later
      let i;
      for (i = 0; i < search.length; i++) {
        if (search[i].resultid == item.resultid) {
          break;
        }
      }
      i += 1;

      // also ignore albums
      let queue = []
      const remaining_items = search.slice(i)
      for (let j = 0; j < remaining_items.length; j++) {
        if (remaining_items[j].trackid) {
          queue.push(remaining_items[j])
        }
      }
      CounterState.setState({ 'playlistQueue': queue })
      collectsyncchanges.push(['playlistQueue', 'set', queue, null])
      // console.log(queue)

    } else if (addSongMode == 'album') {
      // console.log('addsongmode album')

      let new_queue = [];
      if (album.album_details.multiple_disc) {
        let i;
        let j;
        let found = false;

        for (i = 0; i < album.track_details.length; i++) {
          for (j = 0; j < album.track_details[i].data.length; j++) {
            if (found) {
              new_queue = new_queue.concat(album.track_details[i].data.slice(j));
              break;
            }
            if (album.track_details[i].data[j].resultid == item.resultid) {
              found = true;
            }
          }
        }
      } else {
        let i;
        for (i = 0; i < album.track_details[0].data.length; i++) {
          if (album.track_details[0].data[i].resultid == item.resultid) {
            break;
          }
        }
        i += 1;
        new_queue = album.track_details[0].data.slice(i)
      }

      CounterState.setState({ 'playlistQueue': new_queue })
      collectsyncchanges.push(['playlistQueue', 'set', new_queue, null])
    } else if (addSongMode == 'playlist') {

      let i;
      for (i = 0; i < playlist.length; i++) {
        if (playlist[i].trackid == item.trackid) {
          break;
        }
      }
      i += 1;

      // console.log('setqueue',playlist,i)

      CounterState.setState({ 'playlistQueue': playlist.slice(i) })
      collectsyncchanges.push(['playlistQueue', 'set', playlist.slice(i), null])

    }
  }

  CounterState.setState({ 'syncchanges': syncchanges.concat(collectsyncchanges) })
  smartSync()
}






const localized_tracktitle_map = { 'rom': 'tracktitle_translit', 'eng': 'tracktitle_eng', 'jap': 'tracktitle_jap' }

export function localized_tracktitle(item) {
  const { preferredLang } = CounterState.state;
  const preferredLang1 = preferredLang.slice(0, 3)
  const preferredLang2 = preferredLang.slice(3, 6)
  const preferredLang3 = preferredLang.slice(6, 9)

  if (item[localized_tracktitle_map[preferredLang1]]) {
    return item[localized_tracktitle_map[preferredLang1]]
  } else if (item[localized_tracktitle_map[preferredLang2]]) {
    return item[localized_tracktitle_map[preferredLang2]]
  } else {
    return item[localized_tracktitle_map[preferredLang3]]
  }
}


const localized_seriesname_map = { 'rom': 'seriesname_translit', 'eng': 'seriesname_eng', 'jap': 'seriesname_jap' }

export function localized_seriesname(item) {
  const { preferredLang } = CounterState.state;
  const preferredLang1 = preferredLang.slice(0, 3)
  const preferredLang2 = preferredLang.slice(3, 6)
  const preferredLang3 = preferredLang.slice(6, 9)

  if (item[localized_seriesname_map[preferredLang1]]) {
    return item[localized_seriesname_map[preferredLang1]]
  } else if (item[localized_seriesname_map[preferredLang2]]) {
    return item[localized_seriesname_map[preferredLang2]]
  } else {
    return item[localized_seriesname_map[preferredLang3]]
  }
}

const localized_albumname_map = { 'rom': 'albumtitle_translit', 'eng': 'albumtitle_eng', 'jap': 'albumtitle_jap' }

export function localized_albumname(item) {
  const { preferredLang } = CounterState.state;
  const preferredLang1 = preferredLang.slice(0, 3)
  const preferredLang2 = preferredLang.slice(3, 6)
  const preferredLang3 = preferredLang.slice(6, 9)

  if (item[localized_albumname_map[preferredLang1]]) {
    return item[localized_albumname_map[preferredLang1]]
  } else if (item[localized_albumname_map[preferredLang2]]) {
    return item[localized_albumname_map[preferredLang2]]
  } else {
    return item[localized_albumname_map[preferredLang3]]
  }
}

// need to fill in rom to work with preferredLang
const localized_artistname_map = { 'rom': 'artistname_eng', 'eng': 'artistname_eng', 'jap': 'artistname_jap' }

export function localized_artistname(item) {
  const { preferredLang } = CounterState.state;
  const preferredLang1 = preferredLang.slice(0, 3)
  const preferredLang2 = preferredLang.slice(3, 6)
  const preferredLang3 = preferredLang.slice(6, 9)

  if (item[localized_artistname_map[preferredLang1]]) {
    return item[localized_artistname_map[preferredLang1]]
  } else if (item[localized_artistname_map[preferredLang2]]) {
    return item[localized_artistname_map[preferredLang2]]
  } else {
    return item[localized_artistname_map[preferredLang3]]
  }
}




function OptionsMenu(props) { // not used until can figure out how to fucking pass item into menu option
  // try <OptionsMenu item=item/>
  return (
    <View  // for options
      style={{
        paddingLeft: 10, paddingRight: 10, justifyContent: 'center',
      }}>
      <Menu>
        <MenuTrigger>
          <Text style={{ fontSize: 25, lineHeight: 10 }}>{`⋮`}</Text>
        </MenuTrigger>
        <MenuOptions>
          <MenuOption onSelect={() => addtoqueuespecial(props.item)}>
            <Text style={{ color: 'black' }}>Add to queue</Text>
          </MenuOption>
        </MenuOptions>
      </Menu>
    </View>
  )
}

export function Separator(props) {
  return (
    <View
      style={{
        height: props.height || 1,
        width: props.width || "70%",
        marginTop: props.marginTop || 15,
        marginBottom: props.marginBottom || 15,
        alignSelf: 'center',
        backgroundColor: props.backgroundColor || "#5e5e5e",
      }}
    />
  )
}


function Tag_base(props) {
  return (
    <View style={[styles.tagbaseview, {
      backgroundColor: props.backgroundColor,
    }]}>
      <Text style={[styles.tagbasetext, { color: props.color }]}> {props.name} </Text></View>)
}

const tag_1_includes = ['OP', 'ED', 'Insert', 'OST', 'Character', 'Drama']
const tag_2_includes = ['Album']

export function Tag(props) {
  if (tag_2_includes.includes(props.name)) {
    return (<Tag_base name={props.name} color='black' backgroundColor='#1bb11b' />);
  } else {
    return (<Tag_base name={props.name} color='black' backgroundColor='lightgreen' />);
  }
}

export function Tags(props) {
  let tags = props.tags;

  if (!tags) { return null }

  tags = tags.split(' ')

  if (tags[0] == '') {
    return null
  }

  let tagList = []
  for (let i = 0; i < tags.length; i++) {
    tagList.push(<Tag name={tags[i]} />)
  }
  return tagList
}

function addToMyPlaylist(id, type, playlistid) {


  console.log('addToMyPlaylist', id, type, playlistid)


  function inneraddtomyplaylist(ids) {

    const { myplaylist } = CounterState.state;
    const { syncchanges } = CounterState.state;
    // const { playlistrootkey } = CounterState.state;

    console.log('inner', ids, myplaylist, playlistid)

    // find the correct playlist by id
    let playlistposition;
    for (playlistposition = 0; playlistposition < myplaylist.length; playlistposition++) {
      if (myplaylist[playlistposition].id == playlistid) {
        break
      }
    }
    console.log('sigh', playlistposition, myplaylist[playlistposition].entries)

    myplaylist[playlistposition].entries.push(...ids)

    CounterState.setState({ 'myplaylist': myplaylist })

    console.log('setting playlistmodified', myplaylist[playlistposition].id)
    CounterState.setState({ 'playlistmodified': myplaylist[playlistposition].id })
    CounterState.setState({
      'syncchanges': syncchanges.concat([
        ['myplaylist.' + playlistposition + '.entries', 'append', ids, null],
      ])
    })
    smartSync()

    // navigation.dispatch({
    //   ...CommonActions.setParams({ edited: Date.now() }),
    //   source: playlistrootkey,
    // });

    // flash success message
    showMessage({
      message: "Added to playlist successfully!",
      type: "info",
    });

    // need to refresh if previous page is playlist...

  }

  if (type == 'album') {
    // expand id into array of trackid
    // need to do a /album fetch...
    fetch('https://animusic.moe/album?' + new URLSearchParams({
      albumid: id,
    }), {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
    }).then((response) => response.json())
      .then((json) => {
        console.log('HERE2', json.track_details)

        // need to super flatten two layers fml
        let trackids = []
        json.track_details.forEach(disc => {
          disc.data.forEach(track => {
            trackids.push(track.trackid)

          });

        });

        // populate state for search
        return trackids
      })
      .then((id) => { console.log('HERE', id); inneraddtomyplaylist(id) })
      .catch((error) => {
        console.error(error);
        alert(error)
      });

  } else if (type == 'track') {
    inneraddtomyplaylist([id])
  } else {
    alert('type is not album nor track')
  }

}

export function AddToPlaylistModal(props) { // separated here because used in both homescreen and albumscreen

  const { myplaylist } = CounterState.state;
  const [sharedstate, setSharedstate] = CounterState.useState('myplaylist');

  // figure out what 'norm' playlist are there
  const normplaylist = myplaylist.filter((playlist) => playlist.listtype == 'norm')

  // console.log('AddToPlaylistModal', normplaylist, normplaylist.map(x => x.id))

  const defaultValueStore = useRef(normplaylist[0].id || null)

  // console.log('Playlist defaultValue',defaultValueStore.current)

  function confirmFunc(playlistid) {
    // 
    // console.log('addToMyPlaylist', props.id, props.type, playlistid)
    defaultValueStore.current = playlistid;
    addToMyPlaylist(props.id, props.type, playlistid)

  }

  let defaultValue = null
  if (defaultValueStore.current) {
    // look for the correct label to attach
    for (let i = 0; i < normplaylist.length; i++) {
      if (normplaylist[i].id == defaultValueStore.current) {
        defaultValue = [normplaylist[i].name, normplaylist[i].id]
      }
    }
  }

  // console.log('AddToPlaylistModal label', normplaylist.map(x => x.name))
  // console.log('AddToPlaylistModal value', normplaylist.map(x => x.id))
  // console.log('AddToPlaylistModal default', defaultValue.current)


  return (
    <SelectModal visibleModal={props.visibleModal} setvisibleModal={props.setvisibleModal}
      label={normplaylist.map(x => x.name)}
      value={normplaylist.map(x => x.id)}
      default={[defaultValue.current]}
      confirmFunc={confirmFunc} headerText='Select a playlist to add to' />
  )
}


export function fullSync(direction) {

  const { usertoken } = CounterState.state;
  // const { syncchanges } = CounterState.state;

  // CounterState.setState({ 'lastsynctime': -1 })
  const synctime = Date.now()

  console.log('full sync', direction)

  // if not logged in for whatever reason, clear and return
  if (!usertoken) {
    // CounterState.setState({ 'syncchanges': [] });
    alert('wut, not logged in for full sync')
    return null
  }


  if (direction == 'Use remote updated state') { direction = 'pull' }
  if (direction == 'Keep current state') { direction = 'push' }

  // const [sharedstate, setSharedstate] = CounterState.useState('blankplaceholder');

  const { preferredLang } = CounterState.state;
  const { currentTrack } = CounterState.state;
  const { playlistQueue } = CounterState.state;
  const { playlistQueued } = CounterState.state;
  const { playlistQueueSpecial } = CounterState.state;
  const { playlistgreylist } = CounterState.state;
  const { currentplaylist } = CounterState.state;
  const { currentplaylistname } = CounterState.state;
  const { currentpage_search } = CounterState.state;
  const { currentpage_album } = CounterState.state;
  const { myplaylist } = CounterState.state;

  let full_sync_object = {}
  if (direction == 'push') {
    full_sync_object = {
      'preferredLang': preferredLang,
      'currentTrack': currentTrack,
      'playlistQueue': playlistQueue,
      'playlistQueued': playlistQueued,
      'playlistQueueSpecial': playlistQueueSpecial,
      'playlistgreylist': playlistgreylist,
      'currentplaylist': currentplaylist,
      'currentplaylistname': currentplaylistname,
      'currentpage_search': currentpage_search,
      'currentpage_album': currentpage_album,
      'myplaylist': myplaylist,
    }
  } else { // direction == 'pull'
    // pause if playing
    pause()
  }

  fetch('https://animusic.moe/mobilelogin', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'request_type': 'smartsync',
      'token': usertoken, 'synctype': 'full', 'syncchanges': full_sync_object,
      'syncdirection': direction, 'synctime': synctime
    })
  }).then((response) => response.json())
    .then((json) => {
      if (json.success) {

        console.log('full sync success, ', json.synctime)
        CounterState.setState({ 'lastsynctime': json.synctime })

        if (json.syncdirection == 'pull') {
          // save the results
          CounterState.setState({ 'preferredLang': json.syncobj.preferredLang })
          CounterState.setState({ 'currentTrack': json.syncobj.currentTrack })
          CounterState.setState({ 'playlistQueue': json.syncobj.playlistQueue })
          CounterState.setState({ 'playlistQueued': json.syncobj.playlistQueued })
          CounterState.setState({ 'playlistQueueSpecial': json.syncobj.playlistQueueSpecial })
          CounterState.setState({ 'playlistgreylist': json.syncobj.playlistgreylist })
          CounterState.setState({ 'currentplaylist': json.syncobj.currentplaylist })
          CounterState.setState({ 'currentplaylistname': json.syncobj.currentplaylistname })
          CounterState.setState({ 'currentpage_search': json.syncobj.currentpage_search })
          CounterState.setState({ 'currentpage_album': json.syncobj.currentpage_album })
          CounterState.setState({ 'myplaylist': json.syncobj.myplaylist })

          CounterState.setState({ 'forceMusicPanelRefresh': Date.now() })
          //
          if (json.syncobj.currentpage_search) {
            RootNavigation.dispatchmainsearch(json.syncobj.currentpage_search)
          }
        }


      } else {
        const { lastsynctime } = CounterState.state;

        CounterState.setState({ 'lastsynctime': Math.abs(lastsynctime) })
        if (json.message == 'Your device has been logged out. Please log-in again.') {
          CounterState.setState({ 'usertoken': null })
          CounterState.setState({ 'passwordprotected': false })
        } else if (json.message == 'Server has newer state.') {
          // alert('Server has newer state.222')
          CounterState.setState({ 'syncerror': true })

        } else {
          alert('Unhandled error, contact admin, quote error:', json.message)
        }
      }
      return null;
    }).then(() => {

      CounterState.setState({ 'syncing': false });

    })
    .catch((error) => {
      const { lastsynctime } = CounterState.state;
      CounterState.setState({ 'lastsynctime': Math.abs(lastsynctime) })
      console.error(error);
      alert(error)
    });



}


export function smartSync(login_sync = false) {

  const { usertoken } = CounterState.state;


  // if not logged in, clear and return
  if (!usertoken) {
    console.log('not logged in')
    CounterState.setState({ 'syncchanges': [] });
    return null
  }


  const { syncchanges } = CounterState.state;
  const { lastsynctime } = CounterState.state;
  console.log('smart sync', lastsynctime)

  // this should NEVER be called twice simultaneously
  // so we use negative lastsynctime to represent 'syncing in progress'
  const synctime = Date.now()
  if (lastsynctime < 0) {
    console.log('already syncing')
    return false
  }

  if ((Date.now() - lastsynctime) < 10 * 1000) {
    console.log('sync cooldown', Date.now() - lastsynctime)
    return false
  }

  //// Logic path for post-login sync

  CounterState.setState({ 'syncing': true });
  // remote is new account, do a full push
  if (login_sync == 1) {
    console.log('new account sync')
    fullSync('push')
    return null
  }

  // remote is more updated and no local changes, do a pull
  if (login_sync && login_sync > lastsynctime && syncchanges.length == 0) {
    fullSync('pull')
    return null
  }

  CounterState.setState({ 'lastsynctime': -1 * lastsynctime })

  console.log('fetching', syncchanges, lastsynctime, synctime)

  fetch('https://animusic.moe/mobilelogin', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'request_type': 'smartsync',
      'token': usertoken, 'synctype': 'partial', 'syncchanges': syncchanges,
      'synctime_previous': lastsynctime, 'synctime': synctime
    })
  }).then((response) => response.json())
    .then((json) => {
      if (json.success) {
        const { syncchanges } = CounterState.state;

        console.log('sync success, ', json.synctime)
        CounterState.setState({ 'lastsynctime': json.synctime })
        console.log('syncchanges', syncchanges)
        console.log('syncchanges lengths', syncchanges.length, syncchanges.slice(json.noofchanges).length)
        CounterState.setState({ 'syncchanges': syncchanges.slice(json.noofchanges) })
      } else {
        const { lastsynctime } = CounterState.state;

        console.log('fail and set synctime positive')
        CounterState.setState({ 'lastsynctime': Math.abs(lastsynctime) })
        if (json.message == 'Your device has been logged out. Please log-in again.') {
          alert('device is logged out, please log in')
          CounterState.setState({ 'usertoken': null })
          CounterState.setState({ 'passwordprotected': false })
        } else if (json.message == 'Server has newer state.') {
          // alert('server has newer state 111')
          // offer to override or pull
          CounterState.setState({ 'syncerror': true })


        } else {
          alert('Unhandled error, contact admin, quote error:', json.message)
        }
      }
      return null;
    }).then(() => {
      const { syncchanges } = CounterState.state;
      console.log('holy shit', syncchanges.length)
      CounterState.setState({ 'syncing': false });
    })
    .catch((error) => {
      const { lastsynctime } = CounterState.state;
      CounterState.setState({ 'lastsynctime': Math.abs(lastsynctime) })
      console.log('error and set synctime positive')
      console.error(error);
      alert(error)
    });


}


function syncState() {
  const { usertoken } = CounterState.state;

  if (!usertoken) { return null }

  // do we really want to sync EVERYTHING every time?

  // separate into multiple types of sync
  // based on what actions that will modify the sync data

  //// track change

  //// playlist change - 
  // myplaylist

  //// settings change - very lightweight
  // preferredLang

  // send state to server
  fetch('https://animusic.moe/mobilelogin', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'request_type': 'sync',
      'token': usertoken, 'synctype': synctype, 'syncobj': syncobj,
      'synctime': Date.now()
    })
  }).then((response) => response.json())
    .then((json) => {
      if (json.success) {
        if (json.direction == 'toserver') {
          // some modal asking whether to accept server's request.
          // if yes, then restore.
          // if override server
          // also offer log out
          return null
        } else if (json.direction == 'toclient') {

        }

      } else {

        if (json.message == 'Your device has been logged out. Please log-in again.') {
          CounterState.setState({ 'usertoken': null })
          CounterState.setState({ 'passwordprotected': false })
        }
        alert(json.message)
      }
      return null;
    })
    .catch((error) => {
      console.error(error);
      alert(error)
    });
}




const Drawer = createDrawerNavigator();
const Stack = createStackNavigator();

const SettingsStack = createStackNavigator();

const MyPlaylistStack = createStackNavigator();

const FeaturedPlaylistStack = createStackNavigator();

const AlbumDrawerScreenStack = createStackNavigator();

const stackHeaderStyle = {
  headerStyle: {
    backgroundColor: '#171717',
  },
  headerTintColor: 'orange',
  headerTitleStyle: {
    fontWeight: 'bold',
    fontFamily: 'Apane', // !bareapp
    fontSize: 35
  },
  cardStyle: { backgroundColor: '#252525' },
}


function SettingsScreen() { // we don't actually use the stack, but for the sake of copying the header styling, we'll copy code here
  return (
    <SettingsStack.Navigator initialRouteName="Settings"
      screenOptions={stackHeaderStyle}>
      <SettingsStack.Screen name="Settings" component={Settings} options={{
        title: 'Settings', // documenttitle ? documenttitle :
      }} />
    </SettingsStack.Navigator>
  )
}

function MyPlaylistScreen() {
  return (
    <MyPlaylistStack.Navigator initialRouteName="MyPlaylist"
      screenOptions={stackHeaderStyle}>
      <MyPlaylistStack.Screen name="MyPlaylist" component={MyPlaylist} options={{
        title: 'My Playlist',
        params: { editedroute: false }
      }} />
      <MyPlaylistStack.Screen name="Playlist" component={Playlist}
        initialParams={{ search: '' }}
      />
      <MyPlaylistStack.Screen name="PlaylistMAL" component={PlaylistMAL} options={{
        title: 'MAL playlist'
      }} />
    </MyPlaylistStack.Navigator>
  )
}

function FeaturedPlaylistScreen() {
  return (
    <FeaturedPlaylistStack.Navigator initialRouteName="FeaturedPlaylist"
      screenOptions={stackHeaderStyle}>
      <FeaturedPlaylistStack.Screen name="FeaturedPlaylist" component={FeaturedPlaylist} options={{
        title: 'Featured Playlist',
      }} />
    </FeaturedPlaylistStack.Navigator>
  )
}

function MainScreen() {
  return (
    <Stack.Navigator initialRouteName="Home"
      screenOptions={stackHeaderStyle}>
      <Stack.Screen name="Home" component={HomeScreen} options={{
        title: 'Animusic.moe', // documenttitle ? documenttitle :
        style: { width: 200 },  // doens't work
      }}
        initialParams={{ search: '' }}
      />
    </Stack.Navigator>
  )
}

//// Considering whether album should be a standalone top level navigation screen

// hides albumdrawerscreen entry from main drawer
// see https://github.com/react-navigation/react-navigation/issues/2021
const CustomDrawerContent = (props) => {
  const { state, ...rest } = props;
  const newState = { ...state };
  newState.routes = newState.routes.filter(
    (item) => item.name !== 'Album',
  );

  return (
    <DrawerContentScrollView {...props}>
      <DrawerItemList state={newState} {...rest} />
    </DrawerContentScrollView>
  );
};
function AlbumDrawerScreen() { // we don't actually use the stack, but for the sake of copying the header styling, we'll copy code here
  return (
    <AlbumDrawerScreenStack.Navigator initialRouteName="Album"
      screenOptions={stackHeaderStyle}>
      <AlbumDrawerScreenStack.Screen name="Album" component={AlbumScreen} />
    </AlbumDrawerScreenStack.Navigator>
  )
}



// screenOptions={{ headerTitle: props => <LogoTitle {...props} />}}
function App() {

  const [sharedstate, setSharedstate] = CounterState.useState('syncerror');
  const [syncErrorModalVisible, setSyncErrorModalVisible] = useState(false);
  const { lastsynctime } = CounterState.state;
  const { loadedplaceholder } = CounterState.state;
  // const { usertoken } = CounterState.state;
  const { playbackStatus } = CounterState.state;
  const [counterstateloaded, setCounterstateloaded] = useState(0);
  
  const { volume } = CounterState.state; // TOREMOVE @ v14

  if (sharedstate.syncerror && syncErrorModalVisible == false) { setSyncErrorModalVisible(true) }

  // TODO temp disable for persistance
  CounterState.useStorage({ storeName: 'animusicmoe', saveOnBackground: true });

  // console.log('doctitle',documenttitle)


  const [widthForPadding, setWidthForPadding] = useState(Dimensions.get('window').width)

  useEffect(() => {
    Dimensions.addEventListener("change", () => { setWidthForPadding(Dimensions.get('window').width) });
  });

  // adds padding to keep the page out of the player's wrath/position
  function AudioControlPanelSpacing() {
    return (
      // don't make this padding larger than the height of the player itself
      <View style={(widthForPadding > 600) ? { paddingBottom: 120 } : { paddingBottom: 110 }}></View> // !bare_app
      // <View style={{ paddingBottom: 110 }}></View>    // !bare_app
    )
  }
  const [loaded] = useFonts({ // !bareapp
    Apane: require('./assets/fonts/Apane-WyPe4.ttf'),
  });
  // console.log('lastsynctime', lastsynctime)

  if (sharedstate.syncerror == true) {
    CounterState.setState({ 'syncerror': false })
    setSyncErrorModalVisible(true)
  }


  // these 2 functions do a 'run-on-counterstate-init'
  // thing is, counterstate gets init with default values
  // x milliseconds later, it gets loaded with the saved values
  // so we set loadedplaceholder (default 1) to null in the first pass
  // and detect when loadedplaceholder gets populated with the saved (1)
  if (counterstateloaded == 0) {
    // console.log('app console run 1')
    CounterState.setState({ 'loadedplaceholder': null })
    setCounterstateloaded(1)
  }

  if (counterstateloaded == 1) {
    // CounterState.setState({'loadedplaceholder': Date.now()})
    // console.log('app console run 2')
    CounterState.setState({'volume': 0.3}) // TOREMOVE @ v14

    if (loadedplaceholder != null) {
      // console.log('app console run loaded', loadedplaceholder, usertoken)
      smartSync()

      CounterState.setState({ 'userclickedplay': false })
      CounterState.setState({ 'playbackStatus': { ...playbackStatus, 'isPlaying': false } })
      // CounterState.setState({ 'lastsynctime': Math.abs(lastsynctime) }) // resets lastsynctime in case it was resetted

      setCounterstateloaded(2)
    }
  }
  // console.log('console fuck',playbackStatus)

  // console.log('console fuck',loadedplaceholder, usertoken)
  // useEffect(() => {
  //   console.log('pls clear', lastsynctime)
  //   
  //   // 
  //   
  // }, []);

  return (
    <MenuProvider>
      <NavigationContainer ref={navigationRef}>

        <Drawer.Navigator
          initialRouteName="Home"
          drawerStyle={{ backgroundColor: '#464646' }}
          drawerContent={(props) => <CustomDrawerContent {...props} />}
          drawerContentOptions={{
            activeTintColor: '#f3f3f3',
            activeBackgroundColor: '#8d8d8d',
            labelStyle: { fontSize: 20 },
            inactiveTintColor: '#EBEBEB'
          }}>
          <Drawer.Screen name="Home" component={MainScreen} />
          <Drawer.Screen name="My Playlist" component={MyPlaylistScreen} />
          <Drawer.Screen name="Featured Playlist" component={FeaturedPlaylistScreen} />
          <Drawer.Screen name="Settings" component={SettingsScreen} />
          <Drawer.Screen name="Album" component={AlbumDrawerScreen} />
        </Drawer.Navigator>
        <TwoChoicesModal
          type="warning"
          title="Your account state has been updated from a different device"
          description="Choose to either keep current state, or use remote updated state."
          visibility={syncErrorModalVisible}
          setVisibility={setSyncErrorModalVisible}
          confirmFunc={fullSync}
          option1='Use remote updated state'
          option2='Keep current state'
        />
        <AudioControlPanelSpacing />
        <Audiocontrolpanel />
        <FlashMessage position="bottom" animated={false} floating={true} />
      </NavigationContainer>
    </MenuProvider>

  );
}


export default App;