


import React, { useState, useEffect } from 'react';
import {
  Button, View, Text, TextInput, ScrollView, FlatList, BackHandler, ActivityIndicator,
  SafeAreaView, StyleSheet, Image, Dimensions, TouchableHighlight, TouchableOpacity,
} from 'react-native';


import {
  CounterState, styles, baseStyle, Separator, dimensions, Tag, Tags, AddToPlaylistModal,
  setQueue, addtoqueuespecial,
  localized_tracktitle, localized_seriesname, localized_albumname, localized_artistname
} from './App.js'

import { playSong } from "./Music";

import FlashMessage from "react-native-flash-message";
import { showMessage, hideMessage } from "react-native-flash-message";

import { HeaderBackButton } from '@react-navigation/stack';

import { TemplateModal, InputModal, SelectModal } from './Modal.js';

import {
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
} from 'react-native-popup-menu';

import { Svg, Line } from 'react-native-svg';


export function AlbumScreen({ route, navigation }) {
  const [loading, setLoading] = useState(true);
  const [albumdetails, setAlbumdetails] = useState({});
  const { albumitem, } = route.params;
  const [currentTrack, setcurrentTrack] = CounterState.useState('currentTrack');

  const [showaddtoplaylistmodal, setshowaddtoplaylistmodal] = useState(false)
  const [addtoplaylistid, setaddtoplaylistid] = useState(1337)

  CounterState.setState({ 'currentpage_album': albumitem })

  if (albumitem.albumid == undefined) { return null }

  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => (<HeaderBackButton onPress={() => { navigation.goBack() }} tintColor="orange" />),
      headerRight: () => (
        <View
          style={{ marginRight: 10 }}>
          <Button
            onPress={() => navigation.openDrawer()}
            color="grey"
            title="☰"
          />
        </View>
      ),
    });
  }, [navigation]);

  function fetchAlbumDetails(albumid) {

    fetch('https://animusic.moe/album?' + new URLSearchParams({
      albumid: albumid,
    }), {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
    }).then((response) => response.json())
      .then((json) => {

        // populate state for search
        setAlbumdetails(json)
        CounterState.setState({ 'album': json })
        setLoading(false)
        return null;
      })
      .catch((error) => {
        console.error(error);
        alert(error)
      });

  }

  // fetch album details 
  useEffect(() => {
    fetchAlbumDetails(albumitem.albumid)
    setLoading(true)
  }, [albumitem]);



  // updated version from line 144
  const imageHeight = Math.min(300, Math.round(dimensions.height / 3.5));

  // styles
  const albumStyles = StyleSheet.create({

    albumCont: {
      alignItems: 'center',
    },

    // IMAGE

    albumImgCont: {
      marginTop: 30,
      marginBottom: 20,
    },

    albumImg: {
      width: imageHeight,
      height: imageHeight,
      maxHeight: 400,
      minHeight: 50,
      borderRadius: 8,
    },

    // TAG

    albumTagCont: {
      flex: 1,
      justifyContent: 'center',
      flexDirection: 'row',
      marginBottom: 15,
    },

    // TITLES

    albumDescCont: {
      marginBottom: 5,
    },

    albumName: {
      textAlign: 'center',
      fontSize: 21,
      color: '#cacaca',
      lineHeight: 25,
      marginBottom: 5,
    },

    albumSeries: {
      fontSize: 18,
      color: '#fff9a9',
      marginBottom: 5,
      fontWeight: 'bold',
      textAlign: 'center',
    },

    // TRACK SECTION

    trackSection: {
      backgroundColor: '#2E2C2A',
      width: '90%',
      alignSelf: 'center',
      borderRadius: 4,
      marginBottom: 30,
      overflow: "visible",
    },

    trackSectionTitle: {
      marginLeft: '7%',
      marginBottom: 10,
      fontSize: 19,
      fontWeight: 'bold',
      color: 'white',
    },

    trackListItemSep: {
      height: 1,
      width: "100%",
      backgroundColor: "#5e5e5e",
    },

    // TRACK ITEM

    trackItemCont: {
      flex: 1,
      flexDirection: 'row',
      height: 50,
    },

    trackItemContActive: {
      flex: 1,
      flexDirection: 'row',
      height: 50,
      backgroundColor: '#3e6355',
    },

    trackNumCont: {
      width: '10%',
      maxWidth: 60,
      justifyContent: 'center',
      alignItems: 'center',
    },

    trackNum: {
      fontSize: 16,
      fontWeight: 'bold',
      color: '#fff',
      lineHeight: 50,
    },

    trackNameCont: {
      flex: 25,
      justifyContent: 'flex-start',
      flexDirection: 'row',
    },

    trackName: {
      lineHeight: 50,
      fontSize: 17,
      paddingRight: 20,
      color: '#CBCBCB',
    },

    trackMenuCont: {
      position: 'absolute',
      top: 0,
      right: 0,
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })


  const LoadingIndicator = () => {
    return (
      <>
        <ActivityIndicator size="large" color="#a6a6ff" />
        <Text style={styles.middletext}>Loading tracks</Text>
      </>
    )
  }


  // album image, name, animename
  const AlbumInfo = () => {
    let imageUrl
    let seriesName
    let albumName

    seriesName = localized_seriesname(albumitem)
    albumName = localized_albumname(albumitem)

    if (albumitem.albumimage) {
      imageUrl = 'https://static.animusic.moe/static/img/album/' + albumitem.albumimage + '.jpg'
    } else {
      imageUrl = 'https://static.animusic.moe/static/img/album/blank.png'
    }

    return (
      <View style={albumStyles.albumCont}>
        <View style={albumStyles.albumImgCont}>
          <Image source={{ uri: imageUrl }} style={albumStyles.albumImg} />
        </View>


        <View style={albumStyles.albumDescCont}>
          <Text style={albumStyles.albumName}>{albumName}</Text>
          <Text style={albumStyles.albumSeries}>{seriesName}</Text>
        </View>
        <View style={albumStyles.albumTagCont}>
          <Tags tags={albumitem.tags} />
        </View>
      </View>
    )
  }

  // individual tracks
  const Track = ({ item }) => {
    const { currentTrack } = CounterState.state;
    let trackStyle
    let trackName

    if (item.trackid != currentTrack.trackid) {
      trackStyle = albumStyles.trackItemCont
    } else {
      trackStyle = albumStyles.trackItemContActive
    }

    trackName = localized_tracktitle(item)



    return (
      <View>

        <TouchableHighlight onPress={() => { setQueue(item, 'album'); playSong(item) }} underlayColor="#5e5e5e">
          <View style={trackStyle}>
            <View style={albumStyles.trackNumCont}>
              <Text style={albumStyles.trackNum}>{item.tracknumber}</Text>
            </View>
            <View style={albumStyles.trackNameCont}>
              <Text style={albumStyles.trackName} numberOfLines={1}>{trackName}</Text>
              {(item.tag ? <View style={{ justifyContent: 'center', paddingRight: 25, marginLeft: -20 }}><Tag name={item.tag} /></View> : null)}
            </View>
          </View>
        </TouchableHighlight>

        <View style={albumStyles.trackMenuCont}>
          <Menu>
            <MenuTrigger>
              <Text style={styles.ellipsis}>{`⋮`}</Text>
            </MenuTrigger>
            <MenuOptions>
              <MenuOption onSelect={() => addtoqueuespecial(item)}>
                <Text style={{ color: 'black' }}>Add to queue</Text>
              </MenuOption>
              <MenuOption onSelect={() => { setaddtoplaylistid(item.trackid); setshowaddtoplaylistmodal(true) }}>
                <Text style={{ color: 'black' }}>Add to playlist</Text>
              </MenuOption>
            </MenuOptions>
          </Menu>
        </View>

      </View>
    )
  }

  // each section listview which holds tracks
  const sections = ({ item: sectionData }, sectionNum) => {
    let title

    if (sectionNum > 1) {
      title = <Text style={albumStyles.trackSectionTitle}>{sectionData.title}</Text>
    }

    return (
      <View>
        {title}
        <FlatList
          style={albumStyles.trackSection}
          ItemSeparatorComponent={() => <View style={albumStyles.trackListItemSep} />}
          data={sectionData.data}
          renderItem={Track}
          initialNumToRender={sectionData.data.length}
          extraData={currentTrack.currentTrack} />
      </View>
    )
  }


  return (<ScrollView>
    <SafeAreaView style={styles.pageStyle}>
      <AlbumInfo />
      {loading ? <LoadingIndicator />
        : // a listview that holds the sections
        <FlatList
          data={albumdetails.track_details}
          renderItem={(item) => sections(item, albumdetails.track_details.length)}
          extraData={currentTrack.currentTrack}
        />
      }
      <AddToPlaylistModal visibleModal={showaddtoplaylistmodal}
        setvisibleModal={setshowaddtoplaylistmodal}
        id={addtoplaylistid}
        type='track'
      />
    </SafeAreaView>
  </ScrollView>
  )

}
