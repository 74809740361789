
import React, { useState, useEffect } from 'react';
import {
    Button, View, Text, TextInput, ScrollView, FlatList, BackHandler, ActivityIndicator,
    SafeAreaView, StyleSheet, Image, Dimensions, TouchableHighlight, TouchableOpacity,
} from 'react-native';

import {
    CounterState, styles, baseStyle, Separator, setQueue, addtoqueuespecial,
    localized_tracktitle, localized_seriesname, localized_albumname, localized_artistname,
    smartSync
} from './App.js'

import { playSong } from "./Music";



export function fetchfeaturedplaylistsongs(playlistid, playlistname, followupfunc) {
    const { currentplaylist } = CounterState.state;
    const { playlistgreylist } = CounterState.state;
    const { syncchanges } = CounterState.state;

    // set currentplaylist immedietaly, especially important for fetch to work below
    if (currentplaylist != playlistid) { // should not be impt to check, but w/e
        CounterState.setState({ 'currentplaylist': playlistid })
        CounterState.setState({ 'currentplaylistname': playlistname })
        CounterState.setState({
            'syncchanges': syncchanges.concat([
                ['currentplaylist', 'set', playlistid, null],
                ['currentplaylistname', 'set', playlistname, null]
            ])
        })
    }

    // console.log('fetchfeaturedplaylistsongs', playlistgreylist)

    let greylist = []
    if (playlistid in playlistgreylist) {
        greylist = playlistgreylist[playlistid] // must use square bracket because playlistid can be int, wtf js
    }

    // console.log('grey',greylist)

    fetch('https://animusic.moe/featured', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ 'playlistid': playlistid, 'greylist': greylist })
    }).then((response) => response.json())
        .then((json) => {
            const { playlistgreylist } = CounterState.state;
            const { currentplaylist } = CounterState.state;
            const { syncchanges } = CounterState.state;

            let greylist = []
            if (currentplaylist in playlistgreylist) {
                greylist = playlistgreylist[currentplaylist] // must use square bracket because playlistid can be int, wtf js
            }
            const trackids = json.map(({ trackid }) => trackid);
            // console.log('trackids', trackids)
            // fill in the greylist immediately
            // we could also add one by one as they are played, but one function call here easier
            greylist = greylist.concat(trackids).slice(-50)

            // console.log('new greylist', greylist, 'into',currentplaylist)

            // this code actually works to insert/replace the greylist entry
            // why square brackets - https://stackoverflow.com/a/10640182       
            CounterState.setState({ 'playlistgreylist': { ...playlistgreylist, [currentplaylist]: greylist } })
            CounterState.setState({
                'syncchanges': syncchanges.concat([
                    ['playlistgreylist.' + currentplaylist, 'appendcap', trackids, 50]
                ])
            })

            followupfunc(json)
            return null; //json.movies;
        })
        .catch((error) => {
            console.error(error);
            alert(error)
        });
}

export function playfeaturedplaylist(tracks) {
    const { playlistQueue } = CounterState.state;
    const { syncchanges } = CounterState.state;

    console.log('playfeaturedplaylist')

    // console.log('playfeaturedplaylist', tracks)
    // placeholder currenttrack while first track is retrieved
    CounterState.setState({ 'currentTrack': { 'tracktitle_translit': 'Loading playlist...', 'duration': 0 } })
    // erase QueueSpecial 
    CounterState.setState({ 'playlistQueueSpecial': [] })
    // replace Queue
    CounterState.setState({ 'playlistQueue': tracks.slice(1) })

    CounterState.setState({
        'syncchanges': syncchanges.concat([
            ['playlistQueueSpecial', 'set', [], null],
            ['playlistQueue', 'set', tracks.slice(1), null],
        ])
    })

    playSong(tracks[0], false)

    smartSync()
}

export function extendfeaturedplaylist(tracks) {
    console.log('extendfeaturedplaylist', tracks)
    const { playlistQueue } = CounterState.state;
    CounterState.setState({ 'playlistQueue': playlistQueue.concat(tracks) })

    smartSync()
}


export function FeaturedPlaylist({ route, navigation }) {

    const [loading, setLoading] = useState(true);
    const [featuredplaylists, setfeaturedplaylists] = useState([]);

    const [sharedstate2, setSharedstate2] = CounterState.useState('currentplaylist');

    React.useLayoutEffect(() => {
        navigation.setOptions({
            headerRight: () => (
                <View
                    style={{ marginRight: 10 }}>
                    <Button
                        onPress={() => navigation.openDrawer()}
                        color="grey"
                        title="☰"
                    />
                </View>
            ),
        });
    }, [navigation]);

    function FeaturedPlaylistComponent({ item }) {
        const { currentplaylist } = CounterState.state;

        const playlistid = item.playlistid
        const playlistname = item.playlistname

        return (
            <View  // entire row
                style={(playlistid == currentplaylist) ?
                    {
                        flexDirection: "row",
                        backgroundColor: '#464646',
                        marginLeft: 15
                    }
                    : {
                        flexDirection: "row",
                        marginLeft: 15
                    }}>
                <TouchableHighlight onPress={() => { fetchfeaturedplaylistsongs(playlistid, playlistname, playfeaturedplaylist) }} underlayColor="#5e5e5e"
                    style={{
                        flex: 4,
                        paddingTop: 15,
                        paddingBottom: 15,
                    }}>

                    <View // for playlist name 
                        style={{
                            flex: 1,
                            flexDirection: "column",
                        }}>
                        <Text style={{ fontSize: 22, color: '#EBEBEB' }} numberOfLines={1}>{item.playlistname}</Text>
                        {/* numberoflines > 1 doesn't work lol, just don't have long description until react-native fixes it */}
                        {item.description ? <Text style={{ fontSize: 19, color: '#DEDEDE', marginLeft: 10 }} numberOfLines={2} ellipsizeMode='tail'> - {item.description} </Text> : null}
                    </View>

                </TouchableHighlight>

            </View>
        )
    }


    // fetch the featured playlist, TODO change to refresh once an hour, so user dont see loading screen all the time
    function fetchFeaturedPlaylist() {

        fetch('https://animusic.moe/featured', {
            method: 'GET',
        }).then((response) => response.json())
            .then((json) => {
                setfeaturedplaylists(json)
                setLoading(false)
                return null;
            })
            .catch((error) => {
                console.error(error);
                alert(error)
            });
    }


    useEffect(() => {
        fetchFeaturedPlaylist()
    }, []);

    let content
    if (loading) {
        content = (<View><ActivityIndicator size="large" color="#a6a6ff" /></View>)
    } else {
        content = (
            <View>
                <FlatList
                    ItemSeparatorComponent={() => (
                        <View
                            style={{
                                height: 1,
                                width: "100%",
                                backgroundColor: "#5e5e5e",
                            }}
                        />
                    )}
                    data={featuredplaylists}
                    renderItem={FeaturedPlaylistComponent}
                    keyExtractor={(item) => item.resultid}
                    extraData={sharedstate2.currentplaylist}
                    ListEmptyComponent={<Text style={styles.middletext}>No playlist, add a playlist below.</Text>}
                />
                <Separator height={2} />
            </View>
        )
    }

    return (
        <View>
            <Text styles={{}}>  </Text>{/* serves as margin */}
            {content}
        </View>
    )
}
