
import * as React from 'react';

import { CommonActions } from '@react-navigation/native';
import { StackActions } from '@react-navigation/native';

import {
  CounterState
} from './App.js'

// SEE https://reactnavigation.org/docs/navigating-without-navigation-prop/

export const navigationRef = React.createRef();

export function navigate(name, params) {
  navigationRef.current?.navigate(name, params);
}

export function albumnavigate(name, params) {

  const route = navigationRef.current?.getCurrentRoute();

  // console.log("CURRENT ROUTE", route, route.name)

  if (route.name == "Album") {
    // console.log('popping')
    // const popAction = StackActions.pop(1);
    navigationRef.current?.goBack();
  }

  navigationRef.current?.navigate(name, params);
}

export function dispatchmainsearch(param) {
  const { mainrootkey } = CounterState.state;

  navigationRef.current?.dispatch({
    ...CommonActions.setParams({ search: param }),
    source: mainrootkey,
  });
}