import React, { useState, useEffect, useRef } from 'react';
import {
  Button, View, Text, TextInput, ScrollView, FlatList, BackHandler, ActivityIndicator,
  SafeAreaView, StyleSheet, Image, Dimensions, TouchableHighlight, TouchableOpacity,

} from 'react-native';

import { CounterState, styles, baseStyle, smartSync, } from './App.js'

import { Picker } from '@react-native-picker/picker';
import { TemplateModal, InputModal, SelectModal, ConfirmModal } from './Modal.js';


import { Linking } from 'expo';  // !bareapp
//import { Linking } from 'react-native'; // !bareapp <-- android

export function Settings({ route, navigation }) {
  // For now, we just need to have 

  const [modalLogInVisible, setModalLogInVisible] = useState(false);
  const [modalSetPwVisible, setModalSetPwVisible] = useState(false);
  const [modalEnterPwVisible, setModalEnterPwVisible] = useState(false);
  const [modalLogOutOtherDevicesVisible, setLogOutOtherDevicesVisible] = useState(false);
  const [regModalVisible, setRegModalVisible] = useState(false);
  const [loginEmailModalVisible, setLoginEmailModalVisible] = useState(false);
  const [loginPassModalVisible, setLoginPassModalVisible] = useState(false);

  const [temploginhold, settemploginhold] = useState('');

  const [sharedstate, setSharedstate] = CounterState.useState('preferredLang'); // this means when 'preferredLang' changes, component refreshes
  // but sharedstate contains everything anyway
  const [sharedstate2, setSharedstate2] = CounterState.useState('usertoken');
  const [sharedstate3, setSharedstate3] = CounterState.useState('passwordprotected');
  const [sharedstate4, setSharedstate4] = CounterState.useState('syncing');

  const { syncchanges } = CounterState.state;

  const [modalVisible, setModalVisible] = useState(false);
  const [modalClearSettingsVisible, setModalClearSettingsVisible] = useState(false);
  // const { preferredLang } = CounterState.state;

  const { latest_version } = CounterState.state;

  console.log('w', sharedstate.usertoken, sharedstate.preferredLang, sharedstate3.passwordprotected)

  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <View
          style={{ marginRight: 10 }}>
          <Button
            onPress={() => navigation.openDrawer()}
            color="grey"
            title="☰"
          />
        </View>
      ),
    });
  }, [navigation]);

  function login(loginid) {
    fetch('https://animusic.moe/mobilelogin', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ 'request_type': 'registerlogin', 'login_method': 'email', 'login': loginid })
    }).then((response) => response.json())
      .then((json) => {
        if (json.success) {
          CounterState.setState({ 'usertoken': json.token })
        } else {
          if (json.message == 'Need password') {
            setModalSetPwVisible(true)
            settemploginhold(loginid)
          } else {
            alert(json.message)
          }
        }
        return null;
      })
      .catch((error) => {
        console.error(error);
        alert(error)
      });
  }

  function setPassword(pw) {
    fetch('https://animusic.moe/mobilelogin', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ 'request_type': 'setpassword', 'token': sharedstate.usertoken, 'pw': pw })
    }).then((response) => response.json())
      .then((json) => {
        if (json.success) {
          alert('Password set')
          CounterState.setState({ 'passwordprotected': true })
        } else {
          alert(json.message)
        }
        return null;
      })
      .catch((error) => {
        console.error(error);
        alert(error)
      });
  }

  function loginPassword(pw) {
    fetch('https://animusic.moe/mobilelogin', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ 'request_type': 'registerlogin', 'login_method': 'email', 'login': temploginhold, 'pw': pw })
    }).then((response) => response.json())
      .then((json) => {
        if (json.success) {
          CounterState.setState({ 'usertoken': json.token })
          CounterState.setState({ 'passwordprotected': true })
        } else {
          alert(json.message)
        }
        return null;
      })
      .catch((error) => {
        console.error(error);
        alert(error)
      });
  }

  // function logout() {
  //   CounterState.setState({ 'usertoken': null })
  //   CounterState.setState({ 'passwordprotected': false })
  // }

  function clearSettings(ignore) {
	const { usertoken } = CounterState.state;
	const { passwordprotected } = CounterState.state;

	if (usertoken){
		CounterState.reset();
		CounterState.setState({ 'usertoken': usertoken });
		CounterState.setState({ 'passwordprotected': passwordprotected });
		fullSync('push');
	} else {
		CounterState.reset();
	}
	alert('Cleared settings, please refresh page.')
  }

  let settingStyles
  const windowWidth = Dimensions.get('window').width;

  let desktopSettingStyles = StyleSheet.create({
    settingCont: {
      maxWidth: 1000,
      flex: 1,
      alignItems: 'center',
      marginTop: 30,
      width: '90%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },

    settingEntry: {
      width: '100%',
      marginBottom: 30,
    },

    settingTitle: {
      color: '#D8D8D8',
      fontWeight: "500",
      fontSize: 26,
      marginBottom: 5,
      marginLeft: 15,
    },

    settingCard: {
      paddingTop: 15,
      paddingBottom: 15,
      paddingLeft: 20,
      paddingRight: 15,
      backgroundColor: '#2E2C2A',
      borderRadius: 4,
    },

    cardDesc: {
      color: '#DDDDDD',
      fontSize: 16,
      marginBottom: 20,
    },

    // user for order title
    cardTitle: {
      color: 'white',
      fontSize: 17,
      fontWeight: '600',
      marginBottom: 5,
    },

    // used for btn container in accounts
    cardBtns: {
      flexDirection: 'row',
      flexWrap: 'wrap',
    },

    cardWarning: {
      fontSize: 14,
      color: '#FF5757',
      marginTop: 20,
      fontWeight: '600',
    },

    // language picker
    cardPicker: {
      height: 40,
      width: 200,
      backgroundColor: '#FFD076',
      color: 'black',
      fontSize: 17,
      fontWeight: '500',
      borderWidth: 0,
      paddingLeft: 5,
    },

    // login btn
    cardLoginBtn: {
      width: 150,
      marginRight: 30,
      height: 40,
      borderRadius: 4,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#187349',
    },

    // log out other devices btn
    cardLogOutOtherDevicesBtn: {
      width: 150,
      height: 40,
      borderRadius: 4,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#db362c',
    },

    // state clear btn
    cardClearBtn: {
      width: 200,
      height: 40,
      borderRadius: 4,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#db362c',
    },

    // state clear btn text
    cardBtnText: {
      color: 'white',
      fontSize: 14,
      fontWeight: '600',
    },

    discordBtn: {
      marginBottom: 20,
      alignSelf: 'flex-start',
      width: 250,
      backgroundColor: '#7289DA',
      height: 50,
      borderRadius: 5,
      justifyContent: 'center',
      alignContent: 'center',
    },

    discordBtnText: {
      fontSize: 20,
      fontWeight: '500',
      textAlign: 'center',
      color: 'white',
    }
  })

  let mobileSettingStyles = StyleSheet.create({
    settingCont: {
      flex: 1,
      alignItems: 'center',
      marginTop: 20,
      marginLeft: 15,
      marginRight: 15,
    },

    settingEntry: {
      width: '100%',
      marginBottom: 30,
    },

    settingTitle: {
      color: '#D8D8D8',
      fontWeight: '500',
      fontSize: 20,
      marginBottom: 5,
      marginLeft: 15,
    },

    settingCard: {
      paddingTop: 10,
      paddingBottom: 15,
      paddingLeft: 15,
      paddingRight: 10,
      backgroundColor: '#2E2C2A',
      borderRadius: 4,
    },

    cardDesc: {
      color: '#DDDDDD',
      fontSize: 15,
      marginBottom: 20,
    },

    // user for order title
    cardTitle: {
      color: 'white',
      fontSize: 17,
      fontWeight: '600',
      marginBottom: 5,
    },

    // userd for btn container in accounts
    cardBtns: {
      flexDirection: 'row',
      flexWrap: 'wrap',
    },

    cardWarning: {
      fontSize: 14,
      color: '#FF5757',
      marginTop: 20,
      fontWeight: '600',
    },

    // language picker
    cardPicker: {
      height: 40,
      width: 200,
      backgroundColor: '#FFD076',
      color: 'black',
      fontSize: 17,
      fontWeight: '500',
      borderWidth: 0,
      paddingLeft: 5,
    },

    // login btn
    cardLoginBtn: {
      width: 100,
      height: 40,
      marginRight: 20,
      borderRadius: 4,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#187349',
    },

    // log out other devices btn
    cardLogOutOtherDevicesBtn: {
      width: 130,
      height: 40,
      borderRadius: 4,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#db362c',
    },

    // state clear btn
    cardClearBtn: {
      width: 150,
      height: 40,
      borderRadius: 4,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#db362c',
    },

    // state clear btn text
    cardBtnText: {
      color: 'white',
      fontSize: 14,
      fontWeight: '600',
    },

    discordBtn: {
      marginBottom: 20,
      width: 200,
      backgroundColor: '#7289DA',
      height: 50,
      borderRadius: 5,
      justifyContent: 'center',
      alignContent: 'center',
    },

    discordBtnText: {
      fontSize: 20,
      fontWeight: '500',
      textAlign: 'center',
      color: 'white',
    }
  })


  if (windowWidth < 600) {
    settingStyles = mobileSettingStyles
  } else {
    settingStyles = desktopSettingStyles
  }


  const email = useRef('')
  const password = useRef('')

  const register = () => {
    // check if there's a password given
    if (password.current.value !== '') {
      console.log('registering with password ', password.current.value)
      fetch('https://animusic.moe/mobilelogin', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ 'request_type': 'registerlogin', 'login_method': 'email', 'login': email.current.value, 'pw': password.current.value })
      }).then((response) => response.json())
        .then((json) => {
          if (json.success) {
            CounterState.setState({ 'usertoken': json.token })
            CounterState.setState({ 'passwordprotected': true })
            smartSync(json.synctime)
          } else if (json.message == 'Need password') {
            alert('Account already exists.')
          } else if (json.message == 'Wrong password') {
            alert('Account already exists.')
          } else {
            alert(json.message)
          }
          return null;
        })
        .catch((error) => {
          console.error(error);
          alert(error)
        });
    } else {
      console.log('registering without password ')
      fetch('https://animusic.moe/mobilelogin', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ 'request_type': 'registerlogin', 'login_method': 'email', 'login': email.current.value })
      }).then((response) => response.json())
        .then((json) => {
          if (json.success) {
            CounterState.setState({ 'usertoken': json.token })
            smartSync(json.synctime)
          } else if (json.message == 'Need password') {
            alert('Account already exists.')
          } else {
            alert(json.message)
          }
          return null;
        })
        .catch((error) => {
          console.error(error);
          alert(error)
        })
    }
  }

  const loginEmail = () => {
    fetch('https://animusic.moe/mobilelogin', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ 'request_type': 'registerlogin', 'login_method': 'email', 'login': email.current.value })
    }).then((response) => response.json())
      .then((json) => {
        if (json.success) {
          CounterState.setState({ 'usertoken': json.token })
          smartSync(json.synctime)
        } else {
          if (json.message == 'Need password') {
            setLoginPassModalVisible(true)
          } else {
            alert(json.message)
          }
        }
        return null;
      })
      .catch((error) => {
        console.error(error);
        alert(error)
      });
  }

  const loginPass = () => {
    fetch('https://animusic.moe/mobilelogin', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ 'request_type': 'registerlogin', 'login_method': 'email', 'login': email.current.value, 'pw': password.current.value })
    }).then((response) => response.json())
      .then((json) => {
        if (json.success) {
          CounterState.setState({ 'usertoken': json.token })
          CounterState.setState({ 'passwordprotected': true })
          smartSync(json.synctime)
        } else {
          alert(json.message)
        }
        return null;
      })
      .catch((error) => {
        console.error(error);
        alert(error)
      });
  }

  const setPass = () => {
    fetch('https://animusic.moe/mobilelogin', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ 'request_type': 'setpassword', 'token': sharedstate.usertoken, 'pw': password.current.value })
    }).then((response) => response.json())
      .then((json) => {
        if (json.success) {
          alert('Password set')
          CounterState.setState({ 'passwordprotected': true })
        } else {
          alert(json.message)
        }
        return null;
      })
      .catch((error) => {
        console.error(error);
        alert(error)
      });
  }

  const logout = () => {
    // smartSync() // sync before logging out // actually lets not
    fetch('https://animusic.moe/mobilelogin', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ 'request_type': 'logout', 'token': sharedstate.usertoken, })
    }).then((response) => response.json())
      .then((json) => {
        if (json.success) {
          CounterState.setState({ 'usertoken': null })
          CounterState.setState({ 'passwordprotected': false })
          return null
        } else {
          alert(json.message)
        }
        return null;
      })
      .catch((error) => {
        console.error(error);
        alert(error)
      });
  }

  const logOutOtherDevices = () => {
    fetch('https://animusic.moe/mobilelogin', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ 'request_type': 'logoutotherdevices', 'token': sharedstate.usertoken })
    }).then((response) => response.json())
      .then((json) => {
        if (json.success) {
          alert('Other devices have been logged out.')
        } else {
          alert(json.message)
        }
        return null;
      })
      .catch((error) => {
        console.error(error);
        alert(error)
      });
  }

  const Syncingindicator = () => {
    // console.log('sharedstate.syncing',sharedstate.syncing)
    if (sharedstate.syncing) {
      return (
        <View style={{ marginLeft: 20, flexDirection: 'row', marginBottom: 20 }}>
          <Text style={{ color: '#DDDDDD', fontSize: 15, marginRight: 10 }}>
            Syncing...
        </Text>
          <ActivityIndicator size="small" color="#a6a6ff" />
        </View>
      )
    } else {
      return (null)
    }
  }

  return (
    <ScrollView>
      <View style={settingStyles.settingCont}>

        {/* account */}
        {sharedstate.usertoken ?
          // logged in
          <View style={settingStyles.settingEntry}>

            {/* title */}
            <Text style={settingStyles.settingTitle}>Account</Text>

            {/* card */}
            <View style={settingStyles.settingCard}>

              {/* desc */}
              <View style={{ flexDirection: 'row' }}>
                <Text style={settingStyles.cardDesc}>
                  You are now logged in.
                </Text>
                {<Syncingindicator />}

              </View>

              <View style={settingStyles.cardBtns}>

                {/* logout btn */}
                <TouchableOpacity
                  style={settingStyles.cardLoginBtn}
                  onPress={(() => { logout() })}
                >
                  <Text style={settingStyles.cardBtnText}>
                    Logout
                  </Text>
                </TouchableOpacity>

                {/* set password btn */}
                {sharedstate.passwordprotected ? null :
                  <TouchableOpacity
                    style={settingStyles.cardLoginBtn}
                    onPress={(() => { setLoginPassModalVisible(true) })}
                  >
                    <Text style={settingStyles.cardBtnText}>
                      Set Password
                      </Text>
                  </TouchableOpacity>
                }
                {/* log out other devices btn */}
                <TouchableOpacity
                  style={settingStyles.cardLogOutOtherDevicesBtn}
                  onPress={(() => { setLogOutOtherDevicesVisible(true) })}
                >
                  <Text style={settingStyles.cardBtnText}>
                    Log out other devices
				          </Text>
                </TouchableOpacity>

              </View>

              {/* set password */}
              <InputModal
                visibility={loginPassModalVisible}
                setVisibility={setLoginPassModalVisible}
                confirmFunc={setPass}
                cancelFunc={() => {
                  password.current.value = ''
                }}
                inputs={[
                  {
                    title: "Password",
                    placeholder: "Enter here",
                    type: 'password',
                    reference: password
                  }
                ]}
              />

              {/* log out other devices */}
              <ConfirmModal
                type="warning"
                title="Are you sure?"
                description="This will log out all other devices that's logged in to your account."
                visibility={modalLogOutOtherDevicesVisible}
                setVisibility={setLogOutOtherDevicesVisible}
                confirmFunc={logOutOtherDevices}
              />

            </View>

          </View>
          :
          // not logged in
          <View style={settingStyles.settingEntry}>

            {/* title */}
            <Text style={settingStyles.settingTitle}>Account (Experimental, please report bugs)</Text>

            {/* card */}
            <View style={settingStyles.settingCard}>

              {/* desc */}
              <Text style={settingStyles.cardDesc}>
                Logging in will save your settings & playlists on the server.
          </Text>

              {/* if not logged in */}
              <View style={settingStyles.cardBtns}>

                {/* login btn */}
                <TouchableOpacity
                  style={settingStyles.cardLoginBtn}
                  onPress={(() => { setLoginEmailModalVisible(true) })}
                >
                  <Text style={settingStyles.cardBtnText}>
                    Login
                  </Text>
                </TouchableOpacity>

                {/* register btn */}
                <TouchableOpacity
                  style={settingStyles.cardLoginBtn}
                  onPress={(() => { setRegModalVisible(true) })}
                >
                  <Text style={settingStyles.cardBtnText}>
                    Register
                  </Text>
                </TouchableOpacity>

              </View>

            </View>

            {/* register modal */}
            <InputModal
              visibility={regModalVisible}
              setVisibility={setRegModalVisible}
              confirmFunc={register}
              description="You can register using only an email. Optionally you can set a password for added protection."
              cancelFunc={() => {
                email.current.value = ''
                password.current.value = ''
              }}
              inputs={[
                {
                  title: "Email",
                  placeholder: "name@example.com",
                  type: 'emailAddress',
                  reference: email
                },
                {
                  title: "Password (Optional)",
                  placeholder: "Enter here",
                  type: 'password',
                  reference: password
                }
              ]}
            />

            {/* login email */}
            <InputModal
              visibility={loginEmailModalVisible}
              setVisibility={setLoginEmailModalVisible}
              confirmFunc={loginEmail}
              cancelFunc={() => {
                email.current.value = ''
              }}
              inputs={[
                {
                  title: "Email",
                  placeholder: "name@example.com",
                  type: 'emailAddress',
                  reference: email
                }
              ]}
            />

            {/* login password */}
            <InputModal
              visibility={loginPassModalVisible}
              setVisibility={setLoginPassModalVisible}
              confirmFunc={loginPass}
              cancelFunc={() => {
                password.current.value = ''
              }}
              inputs={[
                {
                  title: "Password",
                  placeholder: "Enter here",
                  type: 'password',
                  reference: password
                }
              ]}
            />

          </View>
        }

        {/* language */}
        <View style={settingStyles.settingEntry}>

          {/* title */}
          <Text style={settingStyles.settingTitle}>Language</Text>

          {/* card */}
          <View style={settingStyles.settingCard}>

            {/* desc */}
            <Text style={settingStyles.cardDesc}>
              Sometimes series, album and track names may not be available in your preferred language. You may set the order in which the nearest available gets displayed.
          </Text>

            {/* order title */}
            <Text style={settingStyles.cardTitle}>ORDER</Text>

            {/* order picker */}
            <Picker
              selectedValue={sharedstate.preferredLang}
              style={settingStyles.cardPicker} // !bareapp NOT USED
              // itemStyle={settingStyles.cardPickerItem}
              onValueChange={(itemValue, itemIndex) => {
                CounterState.setState({ 'preferredLang': itemValue })
                CounterState.setState({ 'syncchanges': [...syncchanges, ['preferredLang', 'set', itemValue, null]] })
                console.log('please sytnc', syncchanges)
                smartSync()
                console.log('please sytnc2', syncchanges)
                // ok fuck this shit doesn't work, but at least will be erased when other actions
              }
              }>
              <Picker.Item label="Romaji > Eng > Jap" value="romengjap" />
              <Picker.Item label="Romaji > Jap > Eng" value="romjapeng" />
              <Picker.Item label="Eng > Romaji > Jap" value="engromjap" />
              <Picker.Item label="Jap > Romaji > Eng" value="japromeng" />
            </Picker>

          </View>

        </View>

        {/* saved settings */}
        <View style={settingStyles.settingEntry}>

          {/* title */}
          <Text style={settingStyles.settingTitle}>Saved Settings</Text>

          {/* card */}
          <View style={settingStyles.settingCard}>

            {/* desc */}
            <Text style={settingStyles.cardDesc}>
              If the app throws an error after updates (announcements in discord), try clearing your saved session data.
          </Text>

            {/* state clear btn */}
            <TouchableOpacity
              style={settingStyles.cardClearBtn}
              onPress={(() => { setModalClearSettingsVisible(true) })}
            >
              <Text style={settingStyles.cardBtnText}>
                CLEAR DATA
            </Text>
            </TouchableOpacity>

          </View>

          <ConfirmModal
            type="warning"
            title="Are you sure?"
            description="This will result in the deletion of all your currently saved playlists. This action can’t be undone."
            visibility={modalClearSettingsVisible}
            setVisibility={setModalClearSettingsVisible}
            confirmFunc={clearSettings}
          />

        </View>

        {/* server btn */}
        <TouchableOpacity
          style={settingStyles.discordBtn}
          onPress={() => Linking.openURL('https://discord.gg/W4SNDtdNjj')}>
          <Text style={settingStyles.discordBtnText}>Discord Server</Text>
        </TouchableOpacity>

        {/* App version reminder */}
        <Text>
          current v14 | latest v{latest_version}
        </Text>

      </View>
    </ScrollView>
  )
}

