
// hopefully shared state is working, else fuck go back to separating shit out somehow

// import TrackPlayer from 'react-native-track-player';
// import { useTrackPlayerEvents, TrackPlayerEvents, useTrackPlayerProgress, STATE_PLAYING, STATE_BUFFERING } from 'react-native-track-player';

import { Audio } from 'expo-av';


import React, { useState, useEffect, useRef } from 'react';
import * as RootNavigation from './RootNavigation.js';
import {
  Button, View, Text, TextInput, ScrollView, FlatList, BackHandler, ActivityIndicator,
  SafeAreaView, StyleSheet, Image, Dimensions, TouchableHighlight, TouchableOpacity, SectionList,
  Platform
} from 'react-native';
import Slider from '@react-native-community/slider';
import {
  CounterState, audiocontrolpanelHeight, styles, baseStyle, songComplete,
  addtoqueuespecial, playLastTrack, dimensions,
  localized_tracktitle, localized_seriesname, localized_albumname, localized_artistname,
  smartSync
} from './App.js'

import {
  useResponsiveWidth
} from "react-native-responsive-dimensions";

import { Svg, Path, G, ClipPath, Defs, Rect } from 'react-native-svg';

const sound = new Audio.Sound(); // set onPlaybackStatusUpdate here? // see https://snack.expo.io/bRz26JOHE

// const initialStatus = {
//   progressUpdateIntervalMillis: 500,
//   positionMillis: 0,
//   shouldPlay: false,
//   rate: 1.0,
//   shouldCorrectPitch: false,
//   volume: 0.4,
//   isMuted: false,
//   isLooping: false,
// }

// CounterState.setState({ 'soundObject': sound })

// just fucking use global state fml
// sound.setOnPlaybackStatusUpdate(_onPlaybackStatusUpdate);

export function _onPlaybackStatusUpdate(playbackStatus) {
  CounterState.setState({ 'playbackStatus': playbackStatus })
}


export function play() {
  const { userclickedpause } = CounterState.state;
  console.log('playasync', userclickedpause)
  // CounterState.setState({ userclickedpause: false })
  sound.playAsync(); // .then(CounterState.setState({ userclickedplay: false }));
}

export function pause() {
  console.log('pauseasync')
  CounterState.setState({ userclickedpause: false })
  sound.pauseAsync(); // TODO: consider syncing here, but rmb that full pull sync will call this too
}

export function stop() {
  CounterState.setState({ userclickedpause: false }) // not sure if needed, probably
  sound.stopAsync();
}

export function skip() {
  stop()
  songComplete()
}

export function playlasttrack() {
  stop()
  playLastTrack()
}

export function seek(x) {
  sound.setPositionAsync(x * 1000) // milli
}

// export function testadd1() {
//   sound.loadAsync(
//     {
//       uri: 'http://164.68.114.166:5000/file?f=michishirube.opus', // Load media from the network
//     },
//     initialStatus
//   )
// }

// export function testadd2() {
//   sound.loadAsync(
//     {
//       uri: 'http://164.68.114.166:5000/file?f=sincerely.opus', // Load media from the network
//     },
//     initialStatus
//   )
// }

export function playSong(item, offset = 0, sync = true) { // DONE
  // this is not required to set, only if need to read
  // const [currentTrack, setCurrentTrack] = CounterState.useState('currentTrack'); 
  // const [addSongMode, setAddSongMode] = CounterState.useState('addSongMode');
  const { userclickedplay } = CounterState.state;
  const { syncchanges } = CounterState.state;

  CounterState.setState({ userclickedplay: true }) // set immediately
  CounterState.setState({ showaudioplayer: true }) // set immediately

  // debug
  // const { playlistQueue } = CounterState.state; 
  // console.log('playlistQueue',playlistQueue)

  stop()

  sound.unloadAsync() // .then(()=>{CounterState.setState({ userclickedplay: true })}); // i think this will cause 1 error on startup, but not a problem
  // you would think should setstate userclickedplay at stop above, but stop above is not a promise type function so cant
  // and here is instantaneous as well, so do it here

  // v1: play track song
  // console.log('item')
  // console.log(item)

  // let trackurl = 'https://music.animusic.moe/file?filename=' + item.trackid_encoded // audio files should not have special characters
  let trackurl = 'https://music.animusic.moe/file'

  // console.log('fetch ' + trackurl)

  CounterState.setState({ currentTrack: item })
  CounterState.setState({ 'syncchanges': syncchanges.concat([['currentTrack', 'set', item, null]]) })

  if (sync) { smartSync() }

  let doctitle =



    localized_tracktitle(item) +
    (localized_artistname(item) ? '  / ' + localized_artistname(item) : '') +
    (localized_seriesname(item) ? '  - ' + localized_seriesname(item) : '') +
    (item.tag ? ' [' + item.tag + ']' : '') +
    (item.tags ? ' [' + item.tags + ']' : '')

  document.title = doctitle
  // CounterState.setState({ documenttitle: doctitle })

  // console.log('playsong')
  let formData = new FormData();
  formData.append('filename', item.trackid_encoded);

  fetch(trackurl, {
    method: 'POST', // post will save one round trip from OPTIONS
    headers: {
      Accept: 'application/json',
    },
    body: formData
  }).then((response) => response.json())
    .then((json) => {

      try {

        // console.log('loading sound from ' + 'https://' + json.remote + '/file?' + new URLSearchParams({
        //   c: json.c,
        //   n: json.n,
        // }))
        sound.loadAsync(
          {
            uri: 'https://' + json.remote + '/file?' + json.uri, // Load media from the network
          },
          // initialStatus,
          // false,
        ).then(() => {
          const { userclickedpause } = CounterState.state;
          const { volume } = CounterState.state;
          console.log('loaded and play', userclickedpause);
          if (offset != 0) { console.log('hi offset', offset); seek(offset) };

          // console.log('volume', volume)
          sound.setVolumeAsync(volume)
          // sound.playAsync()
          // if (!userclickedpause) {
          play()
          // }
        }) // temp // .then(sound.playAsync()); 

      } catch (error) {
        console.log('cant play sound');
        console.log(error);
      }

      return null; //json.movies;

    }

    )
    .catch((error) => {
      console.error(error);
      alert(error)
    });

  // console.log('ok.jpg')

}

export default function Audiocontrolpanel(props) { // bottom control panel

  // const { position, bufferedPosition, duration } = useTrackPlayerProgress(500);
  // const [position, setPosition] = useState(4)

  // const [showaudioplayer, setshowaudioplayer] = CounterState.useState('showaudioplayer');
  // const [currentTrack, setCurrentTrack] = CounterState.useState('currentTrack');
  const [playbackStatus, setplaybackStatus] = CounterState.useState('playbackStatus');

  // for the sake of refreshing panel when pulling state from server
  // unfortunate side effect of over-refreshing control panel whenever currenttrack changes.
  // const [sharedstate, setsharedstate] = CounterState.useState('currentTrack');
  // instead we use a specially introduced flag variable
  const [sharedstate, setsharedstate] = CounterState.useState('forceMusicPanelRefresh');

  const { currentTrack } = CounterState.state;
  const { userclickedplay } = CounterState.state;
  const { userclickedpause } = CounterState.state;

  // const [isLoading, setIsLoading] = useState(false); // derived here better because dont cause component reload
  const [amSliding, setAmSliding] = useState(false);
  const [slidingValue, setSlidingValue] = useState(0);

  // const [isbufferingreplacement, setisbufferingreplacement] = useState(false);

  const [expandTrackName, setExpandTrackName] = useState(false)
  const [restrictTrackName, setRestrictTrackName] = useState(false)

  // const sound = new Audio.Sound(); // if defined here then cant use as global variable

  // console.log('forceMusicPanelRefresh',sharedstate.forceMusicPanelRefresh)

  useEffect(() => {
    // setup
    sound.setOnPlaybackStatusUpdate(_onPlaybackStatusUpdate);
    CounterState.setState('userclickedplay', false)
    //  console.log('set playback status update')
  }, []);

  // const currentTrackPreventRefresh = useRef(0)
  // console.log('currentTrackPreventRefresh', currentTrackPreventRefresh.current)

  function PressPlay() {
    console.log('play')

    CounterState.setState({ userclickedpause: false })

    const offset = playbackStatus.playbackStatus.positionMillis / 1000

    sound.getStatusAsync().then((statusobj) => {
      if (statusobj.isLoaded) { play() } else {

        // console.log("WAT", offset)
        playSong(currentTrack, offset)
      }
    }
    )
    // setIsLoading(true); // not used
  }

  function PressPause() {
    const { userclickedpause } = CounterState.state;
    console.log('user press pause...', playbackStatus.playbackStatus.isPlaying)
    if (playbackStatus.playbackStatus.isPlaying) {
      console.log('normal pause')
      pause();
    } else {
      console.log('set userclickedpause true', userclickedpause)
      CounterState.setState({ userclickedpause: true })
      CounterState.setState({ 'forceMusicPanelRefresh': Date.now() })
    }
  }

  function PressBack(position) {
    console.log('back' + position)
    if (position > 3) { // ((position - duration) / duration > 0.1) {
      seek(0);
    } else {
      playlasttrack();
    }
  }
  function PressForward() {
    console.log('next')
    skip();
  }

  function SeekToPosition(x) {
    seek(x)
  }

  let isLoading = false;
  let isPlaying = false;
  let isForcedPause = false;
  let position = 0;

  const width = useResponsiveWidth(100);

  if (!playbackStatus.playbackStatus.isLoaded) {
    // Update your UI for the unloaded state
    if (playbackStatus.playbackStatus.error) {
      console.log(`Encountered a fatal error during playback: ${playbackStatus.error}`);
      // Send Expo team the error on Slack or the forums so we can help you debug!
    }
  } else {
    // Update your UI for the loaded state

    if (playbackStatus.playbackStatus.isPlaying) {
      // Update your UI for the playing state
      // console.log('isPlaying' + playbackStatus.playbackStatus.positionMillis)
      // setPosition(sound.positionMillis / 1000) // this causes an infinite loop fuck
      position = playbackStatus.playbackStatus.positionMillis / 1000;

    } else {
      // Update your UI for the paused state
      console.log('paused')
      position = playbackStatus.playbackStatus.positionMillis / 1000;
    }

    if (playbackStatus.playbackStatus.isBuffering) {
      // Update your UI for the buffering state
      console.log('buffering')
      // never buffers wtf
    }

    if (playbackStatus.playbackStatus.didJustFinish && !playbackStatus.playbackStatus.isLooping) {
      // The player has just finished playing and will stop. Maybe you want to play something else?
      // console.log('finish trigger') // this unfortunately activates multiple times because of  CounterState.useState('currentTrack');
      // if (currentTrackPreventRefresh.current != sharedstate.currentTrack.trackid) {
      console.log('just finish')
      songComplete() // important for this to go first, so we set currentTrackPreventRefresh to the correct incoming trackid
      // currentTrackPreventRefresh.current = sharedstate.currentTrack.trackid
      // }
    }

    isPlaying = (playbackStatus.playbackStatus.isPlaying || playbackStatus.playbackStatus.isBuffering);

    // if (isPlaying){
  }
  if (playbackStatus.playbackStatus.isPlaying && userclickedplay) {
    CounterState.setState({ userclickedplay: false })
    console.log('set userclickedplay false')
  }

  // doesn't work sigh
  // if (isPlaying && !userclickedplay && isbufferingreplacement){
  //   setisbufferingreplacement(false)
  // }

  // because sound.playasync takes time to start playing & isPlaying = true
  // and user can click pause after sound.playasync has been triggered
  if (isPlaying && userclickedpause) {
    console.log('SENDING A PAUSE', isPlaying, userclickedpause)
    pause()
  }

  if (userclickedplay && !userclickedpause) { //|| isbufferingreplacement){ // && (!playbackStatus.playbackStatus.isLoaded || !isPlaying)){
    isLoading = true;
    // console.log('is loading true')
    // setIsLoading(false) // not used
  } else if (userclickedpause) {
    console.log('userclickedpause forced isPlaying false')
    isForcedPause = true;
  }


  // console.log('HMMM', isPlaying, isLoading, userclickedplay, userclickedpause)

  // console.log('isPlaying' + playbackStatus.playbackStatus.isPlaying + ' , ' +
  //  'shouldPlay' + playbackStatus.playbackStatus.shouldPlay + ' , ' + 'userclickedplay' + userclickedplay + ' , ' +
  //  'isbuff' + playbackStatus.playbackStatus.isBuffering+ ' , ' + 'isload' + isLoading + ' , ' + 'isbuffrepl' + isbufferingreplacement)

  if (amSliding) { // for left value text to be correct when seeking
    position = slidingValue;
  }

  // currently always show
  // if (!showaudioplayer.showaudioplayer) { // motherfucker need to double declare it
  //   return null;
  // }
  // console.log('currentTrack',currentTrack)

  let trackName
  let seriesName
  let noTouch = false
  let playerStyles
  const windowWidth = Dimensions.get('window').width;



  const desktopPlayerStyles = StyleSheet.create({
    mainContainer: {
      flexDirection: 'row',
      width: '100%',
      height: 120,
      position: 'fixed',
      bottom: 0,
      left: 0,
      backgroundColor: '#302F2F'
    },

    imageCont: {
      height: 120,
      width: 120,
    },

    image: {
      height: '100%',
      width: '100%',
      resizeMode: 'cover',
    },

    infoAndControls: {
      flex: '1',
      marginLeft: 20,
      marginRight: 20,
      paddingTop: 5,
    },

    trackInfoCont: {
      height: 35,
      flexDirection: 'row',
    },

    trackName: {
      fontSize: 20,
      color: 'white'
    },

    dash: {
      fontSize: 20,
      color: 'white'
    },

    seriesName: {
      fontSize: 19,
      color: '#fff9a9',
      fontWeight: '500'
    },

    tags: {
      fontSize: 17,
      color: '#fff9a9',
    },

    controlsCont: {
      flex: 1,
      position: 'relative',
    },

    sliderAndNums: {
      flexDirection: 'row',
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center'
    },

    numCont: {
      width: 60,
      textAlign: 'center',
    },

    num: {
      color: '#868686',
      fontWeight: '500',
      fontSize: 16
    },

    sliderCont: {
      flex: 0.9,
    },

    playAndChange: {
      flex: 3,
      justifyContent: 'center',
      flexDirection: "row",
      alignItems: 'center',
      paddingBottom: 5,
    },

    prevNext: {
      fill: '#787878',
      width: 80,
      height: 22,
    },

    playPause: {
      fill: '#787878',
      width: 47,
      height: 43,
    },

    loadingCont: {
      borderRadius: 50,
      backgroundColor: '#787878',
      height: 43,
      width: 43,
      marginLeft: 2,
      marginRight: 2,
      justifyContent: 'center',
      alignItems: 'center',
    },
  })

  const mobilePlayerStyles = StyleSheet.create({
    mainContainer: {
      flexDirection: 'row',
      width: '100%',
      height: 110,
      position: 'fixed',
      bottom: 0,
      left: 0,
      backgroundColor: '#302F2F'
    },

    imageCont: {
      position: 'absolute',
      bottom: 5,
      left: 30,
      width: 45,
      height: 45,
      zIndex: 1,
    },

    image: {
      height: '100%',
      width: '100%',
      resizeMode: 'cover',
      borderRadius: 5,
    },

    infoAndControls: {
      flex: 1,
      alignItems: 'center',
      paddingTop: 10,
    },

    trackInfoCont: {
      height: 30,
      flexDirection: 'row',
      maxWidth: '90%'
    },

    trackName: {
      fontSize: 17,
      color: 'white'
    },

    seriesName: {
      fontSize: 17,
      color: '#fff9a9',
    },

    tags: {
      fontSize: 17,
      color: '#fff9a9',
    },

    controlsCont: {
      flex: 1,
      width: '100%'
    },

    sliderAndNums: {
      flexDirection: 'row',
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center'
    },

    numCont: {
      width: 60,
      textAlign: 'center',
    },

    num: {
      color: '#868686',
      fontWeight: '500',
      fontSize: 14,
    },

    sliderCont: {
      flex: 1,
    },

    playAndChange: {
      flex: 3,
      justifyContent: 'center',
      flexDirection: "row",
      alignItems: 'center',
      paddingBottom: 5,
    },

    prevNext: {
      fill: '#787878',
      width: 80,
      height: 18,
    },

    playPause: {
      fill: '#787878',
      width: 37,
      height: 37,
    },

    loadingCont: {
      borderRadius: 50,
      backgroundColor: '#787878',
      height: 37,
      width: 37,
      justifyContent: 'center',
      alignItems: 'center',
    },
  })

  const trackcurrenttrack = useRef(currentTrack)
  const resizingtracktitle = useRef(1)

  if (trackcurrenttrack.current.value != currentTrack) {
    trackcurrenttrack.current.value = currentTrack
    resizingtracktitle.current = 1
    setExpandTrackName(false)
    // console.log("TRACK CHANGED")
  }

  let trackWidthThresholdLower;
  let trackWidthThresholdUpper;

  if (windowWidth < 600) {
    playerStyles = mobilePlayerStyles
    trackWidthThresholdLower = dimensions.width / 2
    trackWidthThresholdUpper = dimensions.width * 0.8
  } else {
    playerStyles = desktopPlayerStyles
    trackWidthThresholdLower = (dimensions.width - 120) / 2
    trackWidthThresholdUpper = (dimensions.width - 120) * 0.8
  }


  // if no track is playing disable touch
  if (!currentTrack.trackid) {
    console.log('DISABLE TOUCH')
    noTouch = true
  }

  // resolve track name and series name
  trackName = localized_tracktitle(currentTrack)
  trackName = trackName ? trackName : 'Quick! '
  seriesName = localized_seriesname(currentTrack)
  seriesName = seriesName ? seriesName : 'Pick a track'

  // resolve tags
  const tags = (currentTrack.tag ? ' [' + currentTrack.tag + ']' : '') +
    (currentTrack.tags ? ' [' + currentTrack.tags + ']' : '')

  // resolve album img 
  const imageUrl = () => {
    // console.log('albumimg')
    if (currentTrack.albumimage) {
      return 'https://static.animusic.moe/static/img/album/' + currentTrack.albumimage + '.jpg'
    } else {
      return 'https://static.animusic.moe/static/img/album/blank.png'
    }
  }

  // navigate to album
  const toAlbumPage = () => {
    RootNavigation.albumnavigate('Album', { screen: 'Album', params: { albumitem: currentTrack } })
  }

  // see https://stackoverflow.com/a/11486026 . ~~ position is VERY IMPORTANT
  const leftNum = ~~(position / 3600) >= 1 ? ~~(position / 3600) + ':' + String(~~((position % 3600) / 60)).padStart(2, '0') + ':' + String((~~position / 60)).padStart(2, '0') : ~~(position / 60) + ':' + String((~~position % 60)).padStart(2, '0')
  const rightNum = currentTrack.trackduration == 0 ? 'Loading..' : (~~(currentTrack.trackduration / 3600) >= 1 ? ~~(currentTrack.trackduration / 3600) + ':' + String(~~((currentTrack.trackduration % 3600) / 60)).padStart(2, '0') + ':' + String((~~currentTrack.trackduration / 60)).padStart(2, '0') : ~~(currentTrack.trackduration / 60) + ':' + String((~~currentTrack.trackduration % 60)).padStart(2, '0'))


  const prevIcon = (<TouchableOpacity onPress={() => PressBack(position)} disabled={noTouch}>
    <Svg viewBox="0 0 23 19" style={playerStyles.prevNext}>
      <Path d="M9.53846 0.386377C8.92692 0.134126 8.2802 0.274824 7.81337 0.743647L0.62559 7.99509C-0.217607 8.84229 -0.203537 10.2116 0.63966 11.0593L7.86262 18.3117C8.32944 18.78 8.9244 18.9212 9.53595 18.6685C10.1465 18.4162 10.4379 17.8203 10.4379 17.1595V1.89536C10.4374 1.23458 10.1505 0.637622 9.53846 0.386377Z" />
      <Path d="M22.0477 0.331689C21.4367 0.0794388 20.7623 0.220136 20.296 0.68896L13.0942 7.9409C12.2515 8.7881 12.2585 10.1569 13.1017 11.0051L20.3211 18.2565C20.7885 18.7249 21.4352 18.8661 22.0472 18.6138C22.6578 18.361 23.0005 17.7656 23.0005 17.1043V1.84117C23 1.17939 22.6593 0.583939 22.0477 0.331689Z" />
    </Svg>
  </TouchableOpacity>
  )

  const nextIcon = (<TouchableOpacity onPress={() => PressForward()} disabled={noTouch}>
    <Svg viewBox="0 0 23 19" style={playerStyles.prevNext}>
      <Path d="M13.4615 18.6136C14.0731 18.8659 14.7198 18.7252 15.1866 18.2564L22.3744 11.0049C23.2176 10.1577 23.2035 8.78843 22.3603 7.94072L15.1374 0.688276C14.6705 0.219955 14.0756 0.0787556 13.464 0.331508C12.8535 0.583758 12.562 1.17971 12.562 1.84049L12.562 17.1046C12.5625 17.7654 12.8495 18.3624 13.4615 18.6136Z" />
      <Path d="M0.952247 18.6683C1.56329 18.9206 2.23765 18.7799 2.70397 18.311L9.90582 11.0591C10.7485 10.2119 10.7415 8.84311 9.89828 7.99491L2.67884 0.743467C2.21152 0.275146 1.5648 0.133947 0.95275 0.386195C0.34221 0.638949 -0.000495329 1.2344 -0.000495387 1.89568L-0.000496722 17.1588C5.80697e-06 17.8206 0.340702 18.4161 0.952247 18.6683Z" />
    </Svg>
  </TouchableOpacity>
  )

  const pausedIcon = (<TouchableOpacity onPress={() => PressPause()} disabled={noTouch}>
    <Svg viewBox="0 0 47 47" style={playerStyles.playPause}>
      <G clipPath="url(#clip0)">
        <Path d="M40.1139 6.88672C30.9323 -2.29488 16.0702 -2.29626 6.88733 6.88672C-2.29427 16.0683 -2.29565 30.9304 6.88733 40.1133C16.0689 49.2949 30.931 49.2962 40.1139 40.1133C49.2954 30.9318 49.2968 16.0697 40.1139 6.88672ZM23.5006 43.1351C12.6737 43.1351 3.86542 34.3268 3.86542 23.4999C3.86542 12.6731 12.6737 3.8649 23.5006 3.8649C34.3274 3.8649 43.1356 12.6732 43.1356 23.5C43.1356 34.3269 34.3274 43.1351 23.5006 43.1351Z" />
        <Path d="M27.4208 14.708C26.355 14.708 25.491 15.572 25.491 16.6378V30.3609C25.491 31.4267 26.355 32.2907 27.4208 32.2907C28.4866 32.2907 29.3506 31.4267 29.3506 30.3609V16.6378C29.3506 15.572 28.4867 14.708 27.4208 14.708Z" />
        <Path d="M19.5791 14.708C18.5133 14.708 17.6493 15.572 17.6493 16.6378V30.3609C17.6493 31.4267 18.5133 32.2907 19.5791 32.2907C20.6449 32.2907 21.5089 31.4267 21.5089 30.3609V16.6378C21.5089 15.572 20.645 14.708 19.5791 14.708Z" />
      </G>
      <Defs>
        <ClipPath id="clip0">
          <Rect width="47" height="47" />
        </ClipPath>
      </Defs>
    </Svg>
  </TouchableOpacity>
  )

  const loadingIcon = (<View style={playerStyles.loadingCont}>
    <TouchableOpacity onPress={() => PressPause()} disabled={noTouch}>
      <ActivityIndicator size="small" color="#fff9a9" />
    </TouchableOpacity>
  </View>
  )

  const playingIcon = (<TouchableOpacity onPress={() => PressPlay()} disabled={noTouch}>
    <Svg viewBox="0 0 47 47" style={playerStyles.playPause}>
      <Path d="M31.252 22.5813L21.4926 15.487C21.1344 15.2274 20.659 15.1882 20.2664 15.39C19.8707 15.5902 19.6236 15.9968 19.6236 16.4363V30.6203C19.6236 31.0645 19.8707 31.4696 20.2664 31.6698C20.4338 31.7542 20.6167 31.7964 20.8013 31.7964C21.0422 31.7964 21.2861 31.7198 21.4926 31.5681L31.252 24.48C31.5601 24.2532 31.74 23.9029 31.74 23.5307C31.7415 23.1522 31.557 22.8034 31.252 22.5813Z" />
      <Path d="M23.5008 0.00292969C10.5195 0.00292969 0 10.5224 0 23.5037C0 36.4803 10.5195 46.9967 23.5008 46.9967C36.4789 46.9967 47 36.4787 47 23.5037C47.0016 10.5224 36.4789 0.00292969 23.5008 0.00292969ZM23.5008 43.0757C12.6904 43.0757 3.92566 34.3157 3.92566 23.5037C3.92566 12.6964 12.6904 3.92546 23.5008 3.92546C34.3096 3.92546 43.0728 12.6949 43.0728 23.5037C43.0743 34.3157 34.3096 43.0757 23.5008 43.0757Z" />
    </Svg>
  </TouchableOpacity>
  )

  // console.log('WTF',playbackStatus.playbackStatus.positionMillis,position)

  // console.log('wtf', isPlaying, isLoading, playbackStatus.playbackStatus,  playbackStatus.playbackStatus.isPlaying, playbackStatus.playbackStatus.isBuffering)
  // seems like it's possible for playbackStatus to report isPlaying when not actually is playing

  // console.log('expandTrackName',expandTrackName, restrictTrackName)

  return (<View style={playerStyles.mainContainer}>

    {/* image */}
    <TouchableOpacity style={playerStyles.imageCont} onPress={() => toAlbumPage()} disabled={noTouch}>
      <Image source={{ uri: imageUrl() }} style={playerStyles.image} />
    </TouchableOpacity>

    {/* everything to the right of image */}
    <View style={playerStyles.infoAndControls}>

      {/* track & anime name */}
      <TouchableOpacity style={playerStyles.trackInfoCont} onPress={() => toAlbumPage()} disabled={noTouch}>
        <Text numberOfLines={1}
          style={(expandTrackName ? [playerStyles.trackName, { flexShrink: 0 }] :
            restrictTrackName ? [playerStyles.trackName, { maxWidth: trackWidthThresholdUpper }] :
              playerStyles.trackName)}

          onLayout={(event) => { // might need to change to onLayout for web
            // console.log('EVENT', event)
            // console.log('w', event.nativeEvent.layout.width)
            // console.log('x', event.nativeEvent.layout.left.x)
            // console.log(trackWidthThresholdLower, trackWidthThresholdUpper)
            if (resizingtracktitle.current == 1 && event.nativeEvent.layout.width < trackWidthThresholdLower) { // calculate 50% width dynamically width - 120/45 - ~5 / 2
              // if (!restrictTrackName){
              setExpandTrackName(true);
              resizingtracktitle.current = 2;
              // console.log('expand', event.nativeEvent.layout.width, trackWidthThresholdLower)
              // }
              // } else if (event.nativeEvent.layout.width > trackWidthThresholdUpper) {
              // 	setRestrictTrackName(true)
              // 	setExpandTrackName(false)
              //   console.log('contract', event.nativeEvent.layout.width, trackWidthThresholdUpper)
            } else if (event.nativeEvent.layout.width > trackWidthThresholdUpper) {
              setExpandTrackName(false);
              setRestrictTrackName(true);
              resizingtracktitle.current = 3;
            }
            // console.log('contract', event.nativeEvent.layout.width, trackWidthThresholdLower)
            // } else {
            // 	setExpandTrackName(false)
            // setRestrictTrackName(false)
            // console.log('normal', event.nativeEvent.layout.width, trackWidthThresholdLower, trackWidthThresholdUpper)
            // }
          }}

        // onTextLayout={(event) => { // might need to change to onLayout for web
        //   console.log('EVENT', event)
        //   console.log('w',event.nativeEvent.lines.width)
        //   console.log('x',event.nativeEvent.lines.x)	
        //   console.log(trackWidthThresholdLower, trackWidthThresholdUpper)
        // if (event.nativeEvent.lines.width < trackWidthThresholdLower){ // calculate 50% width dynamically width - 120/45 - ~5 / 2
        // 	setExpandTrackName(true)
        // } else if (event.nativeEvent.lines.width > trackWidthThresholdUpper) {
        // 	setRestrictTrackName(true)
        // setExpandTrackName(false)
        // } else {
        // 	setExpandTrackName(false)
        // 	setRestrictTrackName(false)
        // }
        // }}
        >{trackName}</Text>
        <Text style={playerStyles.dash}> - </Text>

        {(tags ?
          <Text numberOfLines={1} style={playerStyles.seriesName}>{seriesName}<Text numberOfLines={1} style={playerStyles.tags}>{tags}</Text></Text>
          : <Text numberOfLines={1} style={playerStyles.seriesName}>{seriesName}</Text>)}
      </TouchableOpacity>

      {/* slider & controls */}
      <View style={playerStyles.controlsCont}>

        {/* slider */}
        <View style={playerStyles.sliderAndNums}>

          {/* left num */}
          <View style={playerStyles.numCont}>
            <Text style={playerStyles.num}>{leftNum}</Text>
          </View>

          {/* slider */}
          <View style={playerStyles.sliderCont}>
            <Slider
              disabled={noTouch}
              minimumValue={0}
              maximumValue={currentTrack.trackduration ? currentTrack.trackduration : 1}
              minimumTrackTintColor="#969696" // slider forground
              maximumTrackTintColor="#434343" // slider background
              thumbTintColor="#fff9a9" // circle color
              value={amSliding ? slidingValue : position} // perfect fuck
              onValueChange={(value) => amSliding ? setSlidingValue(value) : undefined} //(value) => setPosition(value)} // change position number
              onSlidingStart={() => { setSlidingValue(position); setAmSliding(true) }} // perfect fuck
              onSlidingComplete={(value) => { seek(value); setAmSliding(false); }} // change track position
            />
          </View>

          {/* right num */}
          <View style={playerStyles.numCont}>
            <Text style={playerStyles.num}>{rightNum}</Text>
          </View>

        </View>

        {/* controls */}
        <View style={playerStyles.playAndChange}>

          {/* prev */}
          {prevIcon}

          {/* play & pause */}
          {isForcedPause ? playingIcon : isPlaying ? pausedIcon : isLoading ? loadingIcon : playingIcon}

          {/* next */}
          {nextIcon}

        </View>

      </View>

    </View>

  </View>
  );
}